
var _gloalClickController = (function() {
    var _inst = {};

    var modalController = require('./modalController.js'),
        _productFollow = require('../components/v2/product/productFollow'),
        _brandFollow = require('../components/v2/brand/brandFollow');

    var _setFollowingState = (target, state) => {
        var followState = state ? 'true' : 'false',
            event = state ? 'unfollowbrand' : 'followbrand';

        target.attr('data-following', followState);
        target.attr('data-event', event);
    };

    var _handleBrandFollowRelated = (target, followingState) => {
        var brandId = target.attr('data-brand-id'),
            genderId = target.attr('data-gender-id');

        if (followingState === true) {
            _brandFollow.followBrand(brandId, genderId);
        } else {
            _brandFollow.unfollowBrand(brandId, genderId);
        }

        if (target.attr('data-change-state') === 'false') {
            return;
        }

        var buttonTargetsSelector = '.'
            + target.attr('class')
            + '[data-brand-id="'
            + target.attr('data-brand-id')
            + '"][data-gender-id="'
            + target.attr('data-gender-id')
            + '"]',

            buttonTargets = $(buttonTargetsSelector);

        _setFollowingState(buttonTargets, followingState);

        var textAttribute = followingState === true ? 'data-text-active' : 'data-text-default';

        if (target.attr(textAttribute)) {
            buttonTargets.html(target.attr(textAttribute));
        }
    };

    _inst.init = () => {

        // window.nav = (event, el) => {
        //     let href = el.dataset.nav;
        //     let target = el.dataset.target || "_top";
        //
        //     if ((event.ctrlKey || event.metaKey)) {
        //         target = "blank";
        //     }
        //
        //     if (href) {
        //         if (target == "blank") {
        //             window.open(href, '_blank');
        //         } else {
        //             location.assign(href);
        //         }
        //     }
        // };

        $('body').on('click', '[data-event="loginmodal"]', function(e) {
            e.preventDefault();
            e.stopImmediatePropagation();

            if (modalController.hasActiveModal) {
                modalController.closeActiveModal(() => {
                    modalController.openLogin();
                });
            } else {
                modalController.openLogin();
            }

        });

        $('body').on('click', '[data-event="signupmodal"]', function(e) {
            e.preventDefault();
            e.stopImmediatePropagation();

            if (modalController.hasActiveModal) {
                modalController.closeActiveModal(() => {
                    modalController.openSignup();
                });
            } else {
                modalController.openSignup();
            }

        });

        $('body').on('click', '[data-event="followproduct"]', function(e) {
            e.preventDefault();
            e.stopImmediatePropagation();

            var target = $(this),
                id = target.attr('data-id'),
                name = target.parent('.product').attr('data-name'),
                price = target.parent('.product').attr('data-price');

            if (!name) {
                name = target.attr('data-name');
                price = target.attr('data-price');
            }

            if (!window.outletSverigeUser) {
                modalController.openSignup({
                    product: {
                        id: id,
                        name: name,
                        price: price
                    }
                });

                return;
            }

            _productFollow.followProduct(id, target);

            if (target.attr('data-text-active')) {
                target.html(target.attr('data-text-active'));
            }
        });

        $('body').on('click', '[data-event="unfollowproduct"]', function(e) {
            e.preventDefault();
            e.stopImmediatePropagation();

            var target = $(this),
                id = target.attr('data-id');

            if (!window.outletSverigeUser) {
                modalController.openSignup();

                return;
            }

            _productFollow.unfollowProduct(id, target);

            if (target.attr('data-text-default')) {
                target.html(target.attr('data-text-default'));
            }
        });

        $('body').on('click', '[data-event="followbrand"]', function(e) {
            e.preventDefault();
            e.stopImmediatePropagation();

            var target = $(this),
                id = target.attr('data-brand-id'),
                name = target.attr('data-name'),
                genderId = target.attr('data-gender-id');

            if (!window.outletSverigeUser) {
                modalController.openSignup({
                    brand: {
                        id: id,
                        name: name,
                        genderId: genderId
                    }
                });

                return;
            }

            _handleBrandFollowRelated(target, true);
        });

        $('body').on('click', '[data-event="unfollowbrand"]', function(e) {
            e.preventDefault();
            e.stopImmediatePropagation();

            if (!window.outletSverigeUser) {
                modalController.openSignup();

                return;
            }

            _handleBrandFollowRelated($(this), false);
        });
    };

    return _inst;
})();

module.exports = _gloalClickController;