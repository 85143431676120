var listSizesTpl = require('../../templates/listSizes.hbs');
var browseView = require('./browseView');
var sizeManager = {
	current : "",
	kids : {
		clothes : [
			["40","Prematur"],
			["50","0-1 Månad"],
			["56","1-2 Månader"],
			["62","2-4 Månader"],
			["68","4-6 månader"],
			["74","6-9 månader"],
			["80","9 månader-1 år"],
			["86","1-1,5 år"],
			["92","1,5-2 år"],
			["98","2-3 år"],
			["104","3-4 år"],
			["110","4-5 år"],
			["116","5-6 år"],
			["122","6-7 år"],
			["128","7-8 år"],
			["134","8-9 år"],
			["140","9-10 år"],
			["146","10-11 år"],
		],
		shoes : ["18","19","20","21","22","23","24","25","26","27","28","29","30","31","32","33"],
		accessories : ['onesize','46','48','50']
	},

	apparel : {
		clothes : ['xxsf', 'xs', 's', 'm', 'l', 'xl', 'xxl', '34','36','38','40','42','44','46','48','50','52'],   // ÖVRIGT
		shoes :  ['35','36','37','38','39','40','41','42','43','44','45','46','47'],  // SKOR
		jeans :  ['27','28','29','30','31','32','33','34','35','36','37','38','39','40'],  // JEANS
		suits :  ['40','42','44','46','48','50','52','54','56','58','60'],  // KOSTYM
		accessories : ['onesize'] // ACCESSOARER
	},

	update : function(type, category){
		var size = "";
		var newSet = "";
		if(type == "barn"){
			if(category == "skor"){
				size = this.kids.shoes;
				newSet= "shoes";
			}
			else if(category == "accessoarer"){
				size = this.kids.accessories;
				newSet= "accessories";
			}
			else{
				size = this.kids.clothes;
				newSet= "clothes";
			}
		}
		else{
			if(category == "skor"){
				size = this.apparel.shoes;
				newSet= "shoes";
			}
			else if(category == "jeans"){
				size = this.apparel.clothes;
				newSet= "jeans";
			}
			else if(category == "kostymer"){
				size = this.apparel.clothes;
				newSet= "suites";
			}
			else if(category == "accessoarer"){
				size = this.apparel.accessories;
				newSet= "accessories";
			}
			else {
				size = this.apparel.clothes;
				newSet= "clothes";
			}
		}
		if(this.current != newSet){
			this.current = newSet;
			browseView.data.size = [];
			this.render(size);
		}	
	},

	render : function(size){
		var display = Array.isArray(size[0]) ? 1 : "";
		var tplData = { "sizes" : size, "display" : display }
		// Todo fix this! $("#size_filter #sizes").html(listSizesTpl(tplData));

		browseView.bindQuickClickFilter();
	}
	

}

module.exports = sizeManager;