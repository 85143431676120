
var _loginView = (function() {

    var apiService = require('../../services/apiService.js');
    var facebookService = require('../../services/facebookService.js');

    return function() {
        var _inst = {

            elId: "",
            preFollow: "",

            $rootElement: null,
            elements: {},

            init: function (el) {
                this.$rootElement = el || $('.login-form');

                this.render();
                this.bindUi();
            },

            setPreFollows: function (preFollow) {

            },

            render: function () {

                this.elements.$form = this.$rootElement.find('form');

                this.elements.$emailField = this.$rootElement.find('input[type=email]');
                this.elements.$emailErrorField = this.$rootElement.find('label[for=email]');

                this.elements.$passwordField = this.$rootElement.find('input[type=password]');
                this.elements.$passwordErrorField = this.$rootElement.find('label[for=password]');

                this.elements.$loginBtn = this.$rootElement.find("button[name=login]");
                this.elements.$facebookBtn = this.$rootElement.find('button[name=fbjoin]');

            },

            bindUi: function () {
                var self = this;

                this.elements.$form.on('submit', function(event) {
                    event.preventDefault();
                    event.stopPropagation();
                });

                this.elements.$loginBtn.on("click", function(event) {
                    event.preventDefault();
                    event.stopImmediatePropagation();

                    var $this = $(this);

                    var email = self.elements.$emailField.val();
                    var password = self.elements.$passwordField.val();

                    $this.attr('data-loading', true);
                    apiService.login(email, password)
                        .then((resp) => {
                            if (resp.status === true) {
                                window.location.href = resp.data.profile;
                            }
                        })
                        .catch((error) => {
                            // Todo: Fix response

                            $this.attr('data-loading', false);
                            self.elements.$emailErrorField.html("Emailen du har skrivit in finns är inte kopplad till något konto.");
                            self.elements.$passwordErrorField.html("Lösenordet du angett stämmer inte med emailen.");

                            self.elements.$emailField.addClass('error');
                            self.elements.$emailErrorField.addClass('active');

                            self.elements.$passwordField.addClass('error');
                            self.elements.$passwordErrorField.addClass('active');
                        });
                });

                this.elements.$facebookBtn.on('click', function(event) {
                    event.preventDefault();
                    event.stopImmediatePropagation();

                    var $this = $(this);

                    $this.attr('data-loading', true);
                    if (facebookService.authToken) {

                        apiService.loginFacebook(facebookService.authToken)
                            .then((resp) => {
                                if (resp.status === true) {
                                    window.location.href = resp.data.profile;
                                }
                            })
                            .catch((err) => {
                                $this.attr('data-loading', false);
                            });

                    } else {
                        facebookService.login()
                            .then(function(response) {
                                apiService.loginFacebook(facebookService.authToken)
                                    .then((resp) => {
                                        if (resp.status === true) {
                                            window.location.href = resp.data.profile;
                                        }
                                    })
                                    .catch((err) => {
                                        $this.attr('data-loading', false);
                                    });
                            })
                            .catch(function(response) {
                                $this.attr('data-loading', false);
                            });
                    }

                })

            },

            unbindUi: function () {
                this.elements.$form.off();
                this.elements.$loginBtn.off();
                this.elements.$facebookBtn.off();
            },

            destroy: function() {
                this.$rootElement = null;
                this.elements = {};
                this.unbindUi();
            }

        }

        return _inst;
    }

})();

module.exports = outlet.core.factorify(_loginView);

