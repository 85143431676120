var welcomeTpl = require("../../templates/welcome.hbs");
var searchSuggestView = require("../search/searchSuggestView");
var deviceService = require('../../services/deviceService.js');
var apiService = require('../../services/apiService.js');
var popAlert = require('../..//widgets/popAlertWidget');
var menuSearch = require('../v2/search/menuSearch');
var modalController = require('../../controllers/modalController');

var menuView = {
    init: function () {
        this.bindUi();
        this.checkForDialog();

        popAlert.init();
    },

    bindUi: function () {

        menuSearch.init();

        $(".profile-toggle").bind("click", function () {
            $("#profile-mobile").toggleClass("display");
        });

        $(".nav-backlight").click(function () {
            $('.dropsdown').removeClass('visible');
        });
        $('.dropsdown').on('mouseover', function (e) {
            e.preventDefault();
            $(e.currentTarget).addClass('visible');
        });
        $('.dropsdown').on('mouseout', function (e) {
            e.preventDefault();
            $(e.currentTarget).removeClass('visible');
        });

        $(".search-toggle").bind("click", function () {
            $("#toggle_menu").toggleClass("active");
            $("nav, ul.nav, .search-mobile-container").toggleClass("display");
            $("#search-mobile").focus();
            $(".main__header").toggleClass('active');
        });

        $(".logout").bind("click", function (e) {
            apiService.logout().then(() => {
                console.log("LOGGED OUT");
            });
        });

        $(".toggle-dropdown").bind("click", function () {
            var parent = $(this).closest(".container");

            parent.find(".toggle-dropdown.active").removeClass("active");
            parent.find(".drop-content-item.active").removeClass("active");

            $(this).addClass("active");
            var activate = $(this).attr("data-change");

            $(".drop-item-" + activate).addClass("active");
            $("#profile-mobile").removeClass("display");
        });

        $("#toggle_menu").bind("click", function () {
            $(this).toggleClass("active");
            $("nav, ul.nav, .search-mobile-container").toggleClass("display");
            $(".main__header").toggleClass('active');

            $("#profile-mobile").removeClass("display");
            $(".search-suggest").removeClass("display");
        });

        $(".dropsdown > a").bind("click", function (e) {
            if (deviceService.isHandheld) {
                e.preventDefault();
                $(".dropsdown.active").removeClass("active");
                $(this).parent(".dropsdown").addClass("active");
                return false;
            }
        });
    },

    checkForDialog: function () {
        var hashed = location.hash;
        if (hashed.indexOf('welcome=new') > -1) {

            $("#dialog-full").addClass("display").html(welcomeTpl());
            $('html, body').animate({
                scrollTop: 0
            }, 750);
            $("#featured_campaign").hide();

            gtag('event', 'conversion', {'send_to': 'AW-1022934866/6WrYCKnr5V0Q0v7i5wM'});
            ga('send', 'event', 'signup', 'success');

            // Display policy privacy modal.
            if (modalController.hasActiveModal) {
                modalController.closeActiveModal(() => {
                    modalController.openPolicy();
                });
            } else {
                modalController.openPolicy();
            }

        }
    }
}
module.exports = menuView;