var _deviceService = (function() {
    var _inst = {};

    var uaParser = require('ua-parser-js'),
        uaObject = null;

    _inst.init = function() {
        uaObject = uaParser();
    };

    Object.defineProperty(_inst, "ua", {get: function() {
        return uaObject;
    }});

    Object.defineProperty(_inst, "isHandheld", {get: function() {
        return uaObject.device.type == 'mobile' || uaObject.device.type == 'tablet';
    }});

    return _inst;
})();

module.exports = _deviceService;