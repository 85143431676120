
var brandsView = {
	letter : "",

	init : function(){
		this.bindUi();
		
	},

	bindUi : function(){
		var self = this;

		$(".abc-filter li").click(function(e){
			self.letterFilter($(this).text());

		});

		$("#brandsearch-full").bind('keyup', function(event){
			self.filterSearch($(this).val());

		});
	},

	letterFilter : function(letter){
		console.log(letter);	
		$('html, body').animate({
	        scrollTop: $("#letterbox_"+letter).offset().top
	    }, 750);
	
	},

	filterSearch : function(letter){
		console.log(letter.length+" "+letter);
		$("#letterbox_"+this.letter+ " .brand-item").removeClass("hide");
		var letter = letter.toLowerCase();
		
		if(letter.length == 0){
			$(".brand-list-group").removeClass("hide").removeClass("searchresults");

			
		}
		else{

			var firstLetter = letter.substr(0,1).toLowerCase();
			console.log(firstLetter);
			$(".brand-list-group").addClass("hide");
			$("#letterbox_"+firstLetter).removeClass("hide").addClass("searchresults");
			$("#letterbox_"+firstLetter+ " .brand-item").addClass("hide");
			$("#letterbox_"+firstLetter+ " .brand-item").each(function(i) {
				var name = $(this).attr("data-name");

				if (name.match(RegExp(letter, 'i'))) {
					$(this).removeClass("hide");

				}
			});

		}

	}

}

module.exports = brandsView;
