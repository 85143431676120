
var _subscriberify = function(instance) {
    "use strict";

    /**
     * Core function to add subscriber functionality to a object
     *
     * @public
     * @module core/subscriberify
     * @type {function}
     *
     * @see module:core/factorify
     * @see module:core/publisherify
     *
     */

    var _arguments = arguments;
    var _instance = instance;

    return (function() {

        var oldType = "";
        if (_instance.hasOwnProperty('type')) {
            oldType = _instance.type + ":";
        }
        Object.defineProperty(_instance, "type", {
            get: function() {
                return oldType + "subscriber";
            },
            configurable: true
        });
        
        if (!_instance.hasOwnProperty('notified')) {
            _instance.notified = function(value) {
                console.warn("Please override the notified function.");
            };
        }

        if (_arguments.length > 1) {
            var resp = _instance;
            for(var i = 1; i < _arguments.length; i++) {
                resp = _arguments[i](resp);
            }
            return resp;
        } else {
            return _instance;
        }

    })();

};

module.exports = _subscriberify;