var Router = {
    routes: [],
    mode: null,
    root: '/',
    config: function(options) {
        this.mode = options && options.mode && options.mode == 'history'
                    && !!(history.pushState) ? 'history' : 'hash';
        this.root = options && options.root ? '/' + this.clearSlashes(options.root) + '/' : '/';
        return this;
    },
    getFragment: function() {
        var fragment = '';
        if(this.mode === 'history') {
            fragment = this.clearSlashes(decodeURI(location.pathname + location.search));
            fragment = fragment.replace(/\?(.*)$/, '');
            fragment = this.root != '/' ? fragment.replace(this.root, '') : fragment;
        } else {
            var match = window.location.href.match(/#(.*)$/);
            fragment = match ? match[1] : '';
        }
        return this.clearSlashes(fragment);
    },
    clearSlashes: function(path) {
        return path.toString().replace(/\/$/, '').replace(/^\//, '');
    },
    add: function(re, handler) {
        if(typeof re == 'function') {
            handler = re;
            re = '';
        }
        this.routes.push({ re: re, handler: handler});
        return this;
    },
    remove: function(param) {
        for(var i=0, r; i<this.routes.length, r = this.routes[i]; i++) {
            if(r.handler === param || r.re.toString() === param.toString()) {
                this.routes.splice(i, 1);
                return this;
            }
        }
        return this;
    },
    flush: function() {
        this.routes = [];
        this.mode = null;
        this.root = '/';
        return this;
    },
    check: function(f, type) {
    	// TYPE = 0 AJAX \\ TYPE = 1 RELOAD
        var fragment = f || this.getFragment();

        // console.log("fragment:", fragment);

        for(var i=0; i<this.routes.length; i++) {
            // console.log("this.routes[i].re", this.routes[i].re);
            var match = fragment.match(this.routes[i].re);
            if(match) {
                // console.log("ROUTER MATCH:", match);

                match.shift();
                match.unshift(type);

                this.routes[i].handler.apply({}, match);
                return this;
            }
        }
        return this;
    },
    listen: function() {
        var self = this;
        var current = self.getFragment();
        var fn = function() {
            if(current !== self.getFragment()) {
                current = self.getFragment();

                // console.log("check:", current);

                // SEND 0 TO SET AJAX-CALL
                self.check(current, 0);

            }
        }
        clearInterval(this.interval);
        this.interval = setInterval(fn, 50);

        return this;
    },
    navigate: function(state, title, path) {
        path = path ? path : '';
        title = title ? title : 'Outletsverige.se';
        state = state ? state : '';
        // ADD TO PREV PAGE
        prevPage.push(this.root + this.clearSlashes(path));

        console.log("NAVIGATE:", path, title, state);
        if(this.mode === 'history') {
            window.history.pushState(state, title, this.root + this.clearSlashes(path));
        } else {
            window.location.href = window.location.href.replace(/#(.*)$/, '') + '#' + path;
        }
        return this;

    }
}

module.exports = Router;
