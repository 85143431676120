var listProductsTpl = require('../../templates/listProducts.hbs');
var breadCrumbsTpl = require('../../templates/breadcrumbs.hbs');
var browseCategories = require('./browseCategoriesFilterView');
var productView = require('../product/productView');
// var followProductView = require('../follow/followProductView');
var followView = require('../follow/followView');

var browseView = {

    // STATE DATA
    data: {
        type: "",
        gender: "",
        category: "",
        subcategory: "",
        brand: [],
        minprice: "",
        maxprice: "",
        reducted: "",
        size: [],
        color: "",
        shopid: "",
        order: "",
        search: "",
        userid: "",
        page: 1,
        url: "",
        brandView: 0,
        symCall: 0
    },

    symCalls: 0,

    products: {},

    init: function () {
        // BOOTSTRAP DATA FROM VIEW
       /* this.products = json_products.data;
        if (json_products.total && $("#totalproducts").length) {
            $("#totalproducts").html(json_products.total);

        }*/

    },

    fetch: function (dataType) {

        var self = this;
        this.symCalls++;
        this.data.symCall = this.symCalls;
        // CHECK IF URL CHANGED AND STATE SHOULD BE FETCHED FROM WINDOW STATE ( gender, category, subcategory)
        if (dataType == "fromstate") {
            this.changeState(window.history.state.filterType, window.history.state.filterData);
        }

        // FETCH NEW DATA
        $.get('/api/browse/', this.data)
            .done(function (response) {

                    // CHECK IF LAST REQUEST = LAST REPSONSE
                    if (response.symCall == self.symCalls) {

                        console.log(response);

                        // RENDER
                        $("#browse_products").html(listProductsTpl(response));
                        $("#totalproducts").html(response.paginator.items);

                        // SET NEW PRODUCT DATA
                        self.products = response.products;

                        // BIND UI
                        self.checkPagination(response);
                        self.bindProductsUi();

                    }
                }
            );
        this.updateBreadCrumbs();
    },

    bindUi: function (element) {
        var self = this;

        // BIND FILTERS
        this.bindCategoryFilterByElement();
        this.bindActiveQuikFilters();
        this.bindToggleFilters();

        // BIND PAGINATE
        $(".pagination a").unbind().bind("click", function (el) {
            if (!$(this).hasClass("disabled")) {
                self.paginate(el.currentTarget);

            }
            // return false;
        });

        // BIND PRODUCTS
        this.bindProductsUi();
    },

    bindCategoryFilterByElement: function () {
        var self = this;

        // BIND PRODUCT CLICK
        $("#category_filter a").unbind().bind("click", function (el) {
            self.clickFilter(el.currentTarget);
            return false;

        });
    },

    bindBrandFilterByElement: function () {
        var self = this;

        // BIND PRODUCT CLICK
        $("#brand_filter a").unbind().bind("click", function (el) {
            self.clickFilter(el.currentTarget);
            return false;

        });
    },

    bindActiveQuikFilters: function () {
        // CHECK RENDERED FILTER & BIND
        var self = this;

        this.bindQuickClickFilter();
        var displayEl = $("#max_price");
        $('#max_price_range').off().on('input', function (el) {
            displayEl.html($(this).val() + " kr");
            return false;

        });

        $('#max_price_range').change(function (el) {
            self.clickQuikFilter(el.currentTarget);
            return false;

        });

        $(".filter_contain.toggleble h3").unbind().bind("click", function () {
            $(this).parent(".filter_contain").toggleClass("closed");
        });

        $(".orderbacklight, .headermobile").unbind().bind("click", function () {
            $(".sortlist").toggleClass("active");
        });

        $("#orderby li:not(.headermobile)").unbind().bind("click", function () {
            $("#orderby li.active").removeClass("active");
            $(this).addClass("active");
            var clickedVal = $(this).attr("id");
            $(".sortlist").removeClass("active");

            if (self.data.order != clickedVal) {
                self.data.order = clickedVal;
                self.data.page = 0;
                self.fetch();

            }
        });
    },

    bindQuickClickFilter: function () {
        var self = this;
        $(".folink").unbind().bind("click", function (el) {
            self.clickQuikFilter(el.currentTarget);
            return false;

        });
    },

    bindProductsUi: function (containEl) {
        var self = this;
        // followProductView.init();

        if (containEl === undefined || containEl == '') {
            containEl = 'browse_products';
        }

        $("#" + containEl + " a").unbind().bind("click", function (e) {
            console.log(e);

            if (e.target.classList.contains('favorite-icon')) {
                return;
            }

            e.preventDefault();
            e.stopImmediatePropagation();
            Router.navigate({'productData': self.products[$(e.currentTarget).attr("id")]}, '', $(e.currentTarget).attr("href"));
        });
    },

    resetPagination: function () {
        this.data.page = 1;

    },

    setPage: function (page) {
        this.data.page = parseInt(page);

    },

    paginate: function (el) {
        $('html, body').animate({
            scrollTop: 0
        }, 750);

        if (this.data.page) {
            this.data.page = ($(el).attr("id") == 'next') ? this.data.page + 1 : this.data.page - 1;
        }
        else {
            this.resetPagination();
        }
        this.fetch("page");


    },

    checkPagination: function (response) {

        if (response.paginator.url.prev != null) {
            $(".pagination #prev").removeClass("disabled");
        }
        else {
            $(".pagination #prev").addClass("disabled");
        }

        if (response.paginator.url.next != null) {
            $(".pagination #next").removeClass("disabled");

        }
        else {
            $(".pagination #next").addClass("disabled");

        }

        $("a#next").attr("href", "#page" + (this.data.page + 1));
        $("a#prev").attr("href", "#page" + (this.data.page - 1));
    },

    clickQuikFilter: function (el) {
        var self = this;
        var changedEl = $(el);
        var newValue = changedEl.attr("data-value");
        var changedValue = changedEl.attr("data-type");

        if (changedValue == "maxprice") {
            newValue = changedEl.val();
            this.data.maxprice = (newValue == 3000) ? "" : newValue;

        }
        else if (changedValue == "size") {
            if (this.data.size.indexOf(newValue) !== -1) {
                changedEl.removeClass("active");
                this.data.size.splice(this.data.size.indexOf(newValue), 1);

            }
            else {
                this.data.size.push(newValue);
                changedEl.addClass("active");

            }
        }
        else if (changedValue == "reducted" || changedValue == "gender" || changedValue == "shop" || changedValue == "color") {
            if (changedEl.hasClass("active")) {
                changedEl.removeClass("active");
                newValue = "";

            }
            else {
                $("[data-type=" + changedValue + "].active").removeClass("active");
                changedEl.addClass("active");

            }
            if (changedValue == "reducted") {
                this.data.reducted = newValue;

            }
            else if (changedValue == "gender") {
                this.data.gender = newValue;

            }
            else if (changedValue == "shop") {
                this.data.shop = newValue;

            }
            else if (changedValue == "color") {
                this.data.color = newValue;

            }
        }
        this.resetState();
        this.fetch();
    },

    clickFilter: function (el) {
        // CATEGORY OR BRAND FILTER
        var self = this;
        var clickedEl = $(el);

        var type = clickedEl.attr("data-type");
        var value = clickedEl.attr("data")

        if (type) {

            // SET NEW STATE AND GET NEW URL
            var newurl = self.changeUrl(type, value);
            newurl = (newurl != '') ? newurl : clickedEl.attr("href");

            if (newurl !== -1) {
                // FETCH THROUG HISTORY NAVIGATION  ( GENDER, CATEGORY, SUBCATEGORY  CHANGED)
                // prevPage.push(newurl);
                Router.navigate({'filterType': type, 'filterData': value}, clickedEl.text(), newurl);

            }
            else {
                // JUST FETCH NEW ( BRAND CHANGED )
                self.changeState(type, value);
                self.fetch("brand");

            }

            // STYLE ACTIVE ELEMENT OR UNSTYLE INACTIVE
            if (clickedEl.parent("li").hasClass("active")) {
                clickedEl.parent("li.active").removeClass("active");
                clickedEl.parents(".filter_contain").removeClass("subactive");

                if (clickedEl.attr("data-multi")) {
                    $("#activebrands #" + clickedEl.parent("li").attr("id")).remove();
                    $("#brands #" + clickedEl.parent("li").attr("id")).removeClass("active");

                }

                if (type == 'gender' || type == 'type') {
                    $("#category_filter li.active").removeClass("active");
                    $(".hero h1").text(clickedEl.text());
                }
            } else {

                if (!clickedEl.attr("data-multi")) {
                    if (type == 'gender' || type == 'type') {
                        $("#category_filter li.active").removeClass("active");
                        clickedEl.parents(".filter_contain").removeClass("subactive");
                        clickedEl.parent("li").addClass("active");
                        $(".hero h1").text(clickedEl.text());
                    } else {
                        $("li.active[data-type='" + type + "'] ul li.active").removeClass("active");
                        $("li.active[data-type='" + type + "']").removeClass("active");
                        clickedEl.parent("li").addClass("active");
                        clickedEl.parents(".filter_contain").addClass("subactive");

                        if (type == 'subcategory') {
                            $("#filter").removeClass("display");
                        }
                        $(".hero h1").text(clickedEl.text());
                    }
                } else {
                    clickedEl.parent("li").addClass("active");
                    clickedEl.parent("li").clone(true).appendTo("#activebrands");
                }
            }

            return false;
        } else {
            return true;
        }
    },

    resetState: function () {
        this.data.page = 1;
    },

    changeState: function (dataType, dataValue) {
        var newurl = '';
        this.resetState();
        console.log(dataType, dataValue);
        if (dataType == 'type') {
            this.data.type = dataValue;
            this.data.subcategory = this.data.category = this.data.gender = '';

        }
        else if (dataType == 'search') {
            this.data.type = 'search';
            this.data.search = dataValue;

        }
        else if (dataType == 'gender') {
            this.data.gender = dataValue;
            this.data.type = this.data.subcategory = this.data.category = '';

        }
        else if (dataType == 'category') {
            this.data.category = (dataValue == this.data.category) ? '' : dataValue;
            this.data.subcategory = '';
            this.data.brand = this.data.brandView ? this.data.brand : [];

        }
        else if (dataType == 'subcategory') {
            this.data.subcategory = (dataValue == this.data.subcategory) ? '' : dataValue;
            this.data.brand = this.data.brandView ? this.data.brand : [];

        }
        else if (dataType == 'brand') {
            if (this.data.brand.indexOf(dataValue) !== -1) {
                this.data.brand.splice(this.data.brand.indexOf(dataValue), 1);

            }
            else {
                this.data.brand.push(dataValue);

            }
        }
        if (this.data.brand.length == 0 && this.data.search == "") {
            browseCategories.reset();
        }

        return newurl;
    },

    changeUrl: function (dataType, dataValue) {
        var newurl = '';
        if (dataType == 'category') {
            if (dataValue == this.data.category) {
                //newurl = this.newUrlFromState();
            }

        }
        else if (dataType == 'subcategory') {
            if (dataValue == this.data.subcategory) {
                //newurl = this.newUrlFromState();

            }
        }
        else if (dataType == 'brand') {
            newurl = -1;
        }
        return newurl;
    },

    updateBreadCrumbs: function () {

        console.log(this.data);

        var breads = [];
        var baseurl = "dam";
        if (this.data.type) {
            breads.push({url: "/" + this.data.type + "/", name: this.data.type});
            baseurl = this.data.type;
        }
        if (this.data.gender && this.data.type == "") {
            breads.push({url: "/" + this.data.gender + "/", name: this.data.gender});
            baseurl = this.data.gender;
        }
        if (this.data.category) {
            breads.push({url: "/" + baseurl + "/" + this.data.category + "/", name: this.data.category});
        }
        if (this.data.subcategory) {
            breads.push({
                url: "/" + baseurl + "/" + this.data.category + "/" + this.data.subcategory + "/",
                name: this.data.subcategory
            });
        }
        if (this.data.brand != "" && this.data.brand.length >= 1) {
            var brandLength = (this.data.brand.length == 1) ? "" : " +" + (this.data.brand.length - 1);
            breads.push({
                url: "/" + baseurl + "/v/" + encodeURI(this.data.brand[0]) + "/",
                name: this.data.brand[0] + brandLength
            });

        }
        this.changeHeroBrands();

        $("#breadcrumbs").html(breadCrumbsTpl({breads: breads}));

    },

    changeHeroBrands: function () {
        var self = this;
        $(".hero .intro, .hero .readmt").remove();
        for (var i = 0, len = self.data.brand.length; i < len; i++) {
            $(".hero .intro").append("<span>" + self.data.brand[i] + "</span>");
        }
    },

    bindToggleFilters: function () {

        $(".toggle-filter").unbind().bind("click", function () {

            $("#filter").removeClass("from-left");

            setTimeout(function () {
                $("#filter").addClass("display");
                $("#category_filter").addClass("closed");
            }, 10);

        });

        $(".backlights, #close-filter").unbind().bind("click", function () {

            $("#filter").removeClass("display");

        });

        $(".toggle-categories").unbind().bind("click", function () {

            $("#filter").addClass("from-left")

            setTimeout(function () {
                $("#filter").addClass("display");
                $("#category_filter").removeClass("closed");
            }, 10);
        });

        $(".readmt").unbind().bind("click", function () {

            $(this).remove();
            $(".hero .intro").addClass("all");

        });

    },

    setBrandView: function () {
        this.data.brandView = 1;
        browseCategories.fetch(this.data, "brand");
        followView.init();

    },

    setSearchView: function () {
        browseCategories.fetch(this.data, "search");

    },


    newUrlFromState: function () {
        if (this.data.type != 0) {
            var url = "/" + this.data.type + "/" + this.data.category + "/";

        }
        else {
            var url = "/" + this.data.gender + "/" + this.data.category + "/";

        }

        console.log(url);
        return url;

    }
}

module.exports = browseView;