
var editCampaginView = {
	init : function(){
		this.bindUi();

	},

	bindUi : function(){
		var self = this;
		$("#title").unbind().bind("keyup", function(e) { self.generateSlug(); });
		$("#date").on("change",function(e) { self.generateSlug(); });
			
		

	},

	generateSlug : function(e){
		var text = $("#title").val();
        text = text.toLowerCase();
        text = text.replace(/[^a-zA-Z0-9]+/g,'-');
        date = $("#date").val();
        $("#slug").val(text+"-"+date);

	}
}
module.exports = editCampaginView;