var newShopView = {

	cats : "",

	init : function(){
		this.bindUi();

		window.onbeforeunload = function(){
		  return 'Vill du verkligen stänga fönstert?';
		};

	},

	bindUi : function(){

		var self = this;
		$('#profileTabs a').click(function (e) { e.preventDefault(); $(this).tab('show'); self.generateSql(); self.removeSqls(); });
		$("#generate").click(function(){ self.generateSql(); });

		this.fetchCategories();

	},

	fetchCategories : function(){
		var self = this;
		$.get('/api/categories/all/', '' )
		 	.done(function( response ) {
		 		self.cats = response;
		 		self.bindCatInputs();

		});
	},

	bindCatInputs : function(){
		var self = this;
		$(".row input").on("focus", function(){
			
			var gender = $(this).parent().parent().attr("genderval");
			var type = $(this).parent().parent().attr("typeval");
			var category = "";

			if($(this).hasClass("subcategory")){
				category = $(this).parent().parent().children(".catholder").children("input.category").val();		
				
			}
			console.log(" G: "+gender+" T: "+type+" C: "+category);
			var suggestdata = "";
			if(type == 0 && gender == 1){
				suggestdata = self.cats.categories[1].subCategory;
			}	
			else if(type == 0 && gender == 2){
				suggestdata = self.cats.categories[0].subCategory;
			}	
			else if(type == 1 ){
				suggestdata = self.cats.categories[2].subCategory;
			}	
			else if(type == 2){
				suggestdata = self.cats.categories[2].subCategory;
			}	

			if(category){
				for (var i = 0, len = suggestdata.length; i < len; i++) {
					if(suggestdata[i]){
						
						if(suggestdata[i].slug == category){
							suggestdata = suggestdata[i].subCategory;
						}
					}
				    
				}
			}
			var suggestArray = [];
			if(suggestdata.length >= 1){
				for (var i = 0, len = suggestdata.length; i < len; i++) {
					suggestArray[i] = suggestdata[i].slug;

				}
			}
			console.log(suggestArray);	
			 $( this ).autocomplete({
		      source: suggestArray,
		      minLength: 0
		    });
			 $( this ).autocomplete( "search", "" );

		});
	},

	generateSql : function(e){

		$("#csvfile").text("");
		var styr = "";
		$(".incat").each(function(){
			console.log($(this).find(".category").val());
			if($(this).attr("vals") != "" && $(this).find(".category").val() != ''){
				var line = "UPDATE product_b SET category = '"+$(this).find(".category").val()+"', subcategory = '"+$(this).find(".subcategory").val()+"' WHERE incategory = '"+$(this).attr("vals")+"' AND type='"+$(this).attr("typeval")+"' ;";
				$("#csvfile").append(line+"\n");
			}
		});

	}

	,

	removeSqls : function(e){

		$("#removesql").text("DELETE FROM pnew WHERE incategory = 'category' \n");

		var styr = "";
		$(".incat").each(function(){
			console.log($(this).find(".category").val());
			if($(this).attr("vals") != "" && $(this).find(".category").val() == '' && $(this).find(".subcategory").val() == ''){
				var line = " OR incategory = '"+$(this).attr("vals")+"' ";
				$("#removesql").append(line+"\n");
			}
		});

	}
}
module.exports = newShopView;