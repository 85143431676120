var joinView = require('../login/joinView');
var Cookies = require("js-cookie");
var followDialogTpl = require('../../templates/followBrandDialog.hbs');

var followView = {
	init : function(){
		this.bindUi();

	},

	bindUi : function(){
		var self = this;
		$('.follow').unbind().bind("click", function (e) {

			var elFollow = $(this);
			e.stopPropagation();
			e.preventDefault();

			var uid = Cookies.get('user_id');
			if(!uid || uid == ''){

				var dataFollow = {
					follow : 'brand',
					id : elFollow.attr("id"),
					type : elFollow.attr("data-type"),
					gender : elFollow.attr("data-gender")

				}
				joinView.init("lightbox-join", dataFollow);

			}
			else{

				self.update(elFollow.attr("id"), elFollow.attr("data-type"), elFollow.attr("data-gender"), elFollow);	
				var altText = elFollow.attr("alt-text");
				elFollow.toggleClass("added");	
				if(altText){
					elFollow.attr("alt-text", elFollow.text());
					elFollow.html(altText);

				}
			}
		});
	},

	update : function(id, type, gender, element){
		$.get('/api/follow/brand/', { "id" : id, "type" : type, "gender" : gender } )
		  	.done(function( response ) {
		  		if(response.status == "removed"){
		  			element.parents(".followcontain").addClass("removed");
		  			
		  		}
		  		else{
		  			element.parents(".followcontain").removeClass("removed");
		  			var animeClass = "bounceInDown fromtop"
		  			if($(window).scrollTop() >= 50){
			  			animeClass = "bounceInRight fromright";	

		  			}
		  			
		  			$("#dialoger").html(followDialogTpl(response)).addClass("animated "+animeClass);
		  			window.setTimeout(function(){ $("#dialoger").removeClass("animated bounceInDown").html(""); }, 4500);

		  		}	
		  	});
	}
}
module.exports = followView;