var browseView = require('./browseView');

var browseCategoryFilterView = {
	
	init : function(){

	},

	fetch : function(state, viewtype){
		var self = this;
		$.get('/api/categories/', state )
		 	.done(function( response ) {
		 		if(viewtype == "search"){
		 			self.checkActiveSearchCategory(response);
		 		}
		 		else{
		 			self.checkActiveCategory(response);

		 		}
			}
		);
	},

	checkActiveCategory : function(categories){
		$("#category_filter .dropdown li, .searchtop").addClass("hide");
		$.each(categories.categories, function( key, value ) {
			$("#slug_"+value.subcategory).removeClass("hide");
		  	$("#slug_"+value.category).removeClass("hide");
		  	
		 });
	},

	checkActiveSearchCategory : function(categories){
		$("#category_filter .dropdown li, .searchtop").addClass("hide");
		var genMatrix = new Array();
		genMatrix[0] = new Array();
		genMatrix[1] = new Array();
		genMatrix[2] = new Array();
		genMatrix[0][1] = "dam";
		genMatrix[0][2] = "herr";
		genMatrix[0][0] = "dam";
		genMatrix[1][0] = "barn";
		genMatrix[1][1] = "barn";
		genMatrix[1][2] = "barn";
		genMatrix[2][0] = "hem";

		$.each(categories.categories, function( key, value ) {
			$("#searchtype_"+genMatrix[value.type][value.gender]+" #slug_"+value.subcategory).removeClass("hide");
		  	$("#searchtype_"+genMatrix[value.type][value.gender]+" #slug_"+value.category).removeClass("hide");
		  	
		 });

		$.each(categories.topslugs, function( key, value ) {
			if(value == 1){
				$("#searchtype_"+key).removeClass("hide");	
			
			}
		 });
	},

	reset : function(){
		$("#category_filter .dropdown li").removeClass("hide");

	}
}	
module.exports = browseCategoryFilterView;