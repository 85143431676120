var browseView = require('../components/browse/browseView');
var browseBrandFilterView = require('../components/browse/browseBrandFilterView');
var productView = require('../components/product/productView');
var sizeManager = require('../components/browse/sizeManager');
var previousProducts = require('../components/product/previousProducts');

var browseController = {

    init: function (type, category, subcategory, brand) {

        // PREP TEMPLATE
        browseView.init();
        if (type == 'search') {
            browseView.changeState('search', $("#searchterm").attr("data-query"));
            browseView.setSearchView();

        } else if (type == 'herr' || type == 'dam') {
            var gender = type;
            browseView.changeState('type', 0);
            browseView.changeState('gender', gender);
        } else {
            browseView.changeState('type', type);
        }

        if (category) {
            browseView.changeState('category', category);
        }

        if (subcategory) { // && ($("#meta_subcategory").length <= 0)
            browseView.changeState('subcategory', subcategory);
        }

        if (brand) {
            // GET JSONENCODED BRAND URL FROM VIEW ( PRODUCTS/INDEX )
            brand = active_brand;
            browseView.changeState('brand', brand);
            browseView.setBrandView();
        }

        browseBrandFilterView.bindToggleUi();
        sizeManager.update(browseView.data.type, browseView.data.category);

        if (document.URL.indexOf('#page') != -1) {
            var pageHash = document.URL.substr(document.URL.indexOf('#page') + 5);
            browseView.setPage(pageHash);
            browseView.fetch();
        }

        browseView.bindUi();

        // DISPLAY FEATURED CAMPAIGNS
        $("#featured_campaign").addClass("displayed");
        setTimeout(function () {
            $("#featured_campaign").addClass("display");
        }, 3000);

        previousProducts.render();
    },

    ajaxInit: function () {

        if (productView.checkBackButton()) {

            console.log(window.history);
            try {
                if (window.history.state.filterType == "category") {
                    sizeManager.update(browseView.data.type, browseView.data.category);
                }

                browseView.fetch("fromstate");

                if (window.history.state.filterType != 'brand') {
                    browseBrandFilterView.bindToggleUi();
                }
            } catch (e) {
                console.warn(e);
                window.location.reload();
            }

        }

    }
}

module.exports = browseController;
