var _onTypeSearchFactory = outlet.core.factorify(function() {
    var _inst = {};

    // Dependencies
    var _apiService = require('../../../../services/apiService');

    var _blockResponse = false,
        _xhr = null,
        _eventDispatcher = null,
        _ignoreKeyUpEvents = [
            37, 38, 39, 40
        ];

    var _search = query => {
        if (_xhr && _xhr.readyState !== 4) {
            _xhr.abort();
        }

        _inst.opts.opts.onRequest();

        _xhr = _apiService.onKeySearch(_inst.opts.url, query);

        _xhr.then((response) => {
            if (_blockResponse) {
                return;
            }

            _inst.opts.opts.onSuccess(response);
        }).catch((response) => {
            _inst.opts.opts.onError(response);
        });
    };

    var _bindUi = () => {
        _inst.opts.selector.on('input', function (event) {
            if (_ignoreKeyUpEvents.indexOf(event.which) > -1) {

                return;
            }

            var query = $(this).val();

            if (query.length < _inst.opts.opts.minLength) {
                _blockResponse = true;

                _inst.opts.opts.onUnderMinLength();

                return;
            }

            _blockResponse = false;

            if (_eventDispatcher) clearTimeout(_eventDispatcher);
            _eventDispatcher = setTimeout(function () {
                _search(query);
            }, _inst.opts.opts.requestDelay);
        })
    };

    _inst.init = () => {
        _bindUi();
    };


    return _inst;
});

var _onTypeSearch = (function ($) {
    $.fn.onTypeSearch = function(url, opts) {

        opts = $.extend({
            minLength: 3,
            requestDelay: 200,
            onRequest: function () {},
            onSuccess: function () {},
            onError: function () {},
            onUnderMinLength: function () {}
        }, opts);

        var search = _onTypeSearchFactory.create({
            url: url,
            selector: this,
            opts: opts
        });

        search.init();
    };
    
    return this;
})($);

module.exports = _onTypeSearch;