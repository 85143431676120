var browseView = require('./browseView');
var listBrandsTpl = require('../../templates/listBrands.hbs');

var browseBrandFilterView = {

	data : {
		
	},

	brands : {},

	el : $("#brand_filter"),

	init : function(){
		this.fetch();
	},

	fetch : function(){
		var state = browseView.data;
		var self = this;

		$.get('/api/brands/', state )
		 	.done(function( response ) {
		 		console.log(browseView.data);
		 		var toptype = browseView.data.gender ? browseView.data.gender : browseView.data.type;
				var tplData = { "brands" : response.brands, "gender_url" : toptype, "active_brands" : browseView.data.brand}
				$("#brand_filter #brands").html(listBrandsTpl(tplData));
				self.brands = response.brands;

				// RESET ACTIVE
				$("#activebrands").html("");
				self.bindUi();
				self.checkActive();

		});
	},

	bindToggleUi : function(){
		var self = this;
		var parentEl = $(".filter_contain#brand_filter");
		
		if(!parentEl.hasClass("closed")){
			this.fetch();
		}
		
		$(".filter_contain#brand_filter h3").unbind().bind("click", function(){
			parentEl.toggleClass("closed");
			if(!parentEl.hasClass("closed")){
				self.fetch();

			}
		});
	},

	bindUi : function(){
		var self = this;
		browseView.bindBrandFilterByElement();
		$("#search_brand").unbind().bind("keyup", function(e) { self.filterBrands(e); });
	},

	filterBrands : function (el) {
		var searchTerm = $(el.currentTarget).val();
		
		if(searchTerm.length > 2){
			$("#brands li").addClass("hide");
			$.each(this.brands, function(i, v){
				if(v.name.search(new RegExp(searchTerm, "i")) !== -1){
					console.log(v.name + "matched "+ searchTerm );
					$("#brands li#"+v.id).removeClass("hide");
					
				}
			});
		}
		else{
			$("#brands li").removeClass("hide");
		}
		
	},

	checkActive : function(){
		$("#brand_filter li").each(function(el){
			if( browseView.data.brand.indexOf($(this).children("a").attr("data")) !== -1 ){
				$(this).addClass("active");
			}
		});
	}
}

module.exports = browseBrandFilterView;