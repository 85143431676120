
var _dialogFactory = outlet.core.factorify(function() {
    var _inst = {};

    _inst.$el = null;

    _inst.render = () => {
        _inst.$el = $('<div class="dialog__item" data-identifier="' + _inst.opts.identifier + '">'+_inst.opts.content+'</div>');
        _inst.opts.$container.prepend(_inst.$el);

        _inst.$el.on('click', '.dialog__closebtn', function(e) {
            e.preventDefault();
            e.stopImmediatePropagation();

            _inst.destroy();
        });

        setTimeout(function() {
            _inst.$el.attr('data-state', 'active');
        }, 200);

        setTimeout(_inst.destroy, _inst.opts.delay);
    }

    _inst.destroy = () => {
        _inst.$el.attr('data-state', 'removed');
        _inst.$el.off();
        setTimeout(function() {
            _inst.$el.remove();
            _inst.opts.controller.didRemoveDialog(_inst);

            _inst.opts = null;
            _inst.$el = null;
        }, 1000);
    }

    return _inst;
});

var _dialogController = (function() {
    var _inst = {};

    var _elements = {
        $container: null
    };

    var _activeDialogs = [];
    var _activeIdentifiers = [];

    var _identifierExists = (identifier) => {
        return _activeIdentifiers.indexOf(identifier) > -1;
    };

    _inst.init = () => {
        _elements.$container = $('<div class="dialog__container"></div>');
        $('body').append(_elements.$container);

        /*_inst.appendDialog(_templates.followBrandDialog({}));

        setTimeout(function() {

            _inst.appendDialog(_templates.followProductDialog({
                price: "9999",
                img: "https://images.booztx.com/falke-sport/400x523/fst39610_cyve_v6714.jpg"
            }));

        }, 2000);

        setTimeout(function() {

            _inst.appendDialog(_templates.followBrandDialog({}));

        }, 4000);

        setTimeout(function() {

            _inst.appendDialog(_templates.followProductDialog({
                price: "9999",
                img: "https://images.booztx.com/falke-sport/400x523/fst39610_cyve_v6714.jpg"
            }));

        }, 7000);*/
    };

    _inst.appendDialog = (content, identifier, closeDelay) => {
        closeDelay = closeDelay || 3000;

        if (_identifierExists(identifier)) {
            return;
        }

        var dialog = _dialogFactory.create({
            $container: _elements.$container,
            delay: closeDelay,
            content: content,
            controller: _inst,
            identifier: identifier
        });

        _activeIdentifiers.push(identifier);
        _activeDialogs.push(dialog);

        dialog.render();

        if (!$('body').hasClass('dialog__active')) {
            $('body').addClass('dialog__active');
        }
    }

    _inst.didRemoveDialog = (inst) => {
        const i = _activeDialogs.indexOf(inst);
        if (i !== -1) {
            _activeDialogs.splice(i, 1);

            const identifierIndex = _activeIdentifiers.indexOf(inst.opts.identifier);

            if (identifierIndex !== -1) {
                _activeIdentifiers.splice(identifierIndex, 1);
            }
        }

        if (_activeDialogs.length === 0) {
            $('body').removeClass('dialog__active');
        }
    }

    return _inst;
})();

module.exports = _dialogController;