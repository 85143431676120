var searchResultView = require("../../templates/searchSuggest.hbs");

var searchSuggestView = {

    data: {},

    place: 0,

    count: 0,

    el: $("#search"),

    suggestId: "search-suggest",

    init: function (el) {
        this.el = $("#search-menu-desctop");

        if ($(window).width() <= 906) {
            this.el = $("#search-mobile");
            this.suggestId = "search-suggest-mobile";

        }

        this.bindUi();

    },

    bindUi: function () {
        var self = this;

        $(".searchheader").bind('click', function () {
            $(".search-suggest").removeClass("display");
        });

        this.el.on('focusout', function(e) {
            $(".search-suggest").removeClass("display");

        });

        this.el.unbind('focus');
        this.el.bind('focus', function () {
            var suggests = localStorage.getItem('suggesteddata');
            var suggestData = JSON.parse(suggests);
            if (!suggestData || suggestData.length < 1) {
                console.log("no suggs");
            } else if (suggestData && suggestData.length >= 1) {
                self.place = 0;
                //self.count = suggestData.length;
                $("#" + self.suggestId).html(searchResultView({'suggests': suggestData}))
                self.count = $("#" + self.suggestId + " li").length;
                $(".search-suggest").addClass("display");
            }
        });

        this.el.unbind('keyup');
        this.el.bind('keyup', function (event) {
            console.log(event.which)
            if (event.which != 0 && event.which != 40 && event.which != 38 && event.which != 13) {
                self.suggestSearches(event);
            }
            if (event.which == 27) { // ESC
                self.el.blur();
                $(".search-suggest").removeClass("display");
            }
        });

        this.el.unbind('keypress');
        this.el.bind('keypress', function (event) {
            if (event.which == 13) {
                self.submitSearch(event);
            }
        });

        $(document).keydown(function (e) {
            if (e.which == 40 || e.which == 38) {
                $("#" + self.suggestId + " ul li a.active").removeClass("active");
                if (e.which == 40) {
                    if (self.place < self.count) {
                        self.place++;

                    }
                }
                else if (e.which == 38) {
                    if (self.place > 0) {
                        self.place--

                    }
                }
                $("#" + self.suggestId + " ul li:nth-child(" + self.place + ") a").addClass("active");
            }
        });
    },

    submitSearch: function () {
        var self = this;
        var url = $("#" + self.suggestId + " ul li:nth-child(" + self.place + ") a").attr("href");
        var name = $("#" + self.suggestId + " ul li:nth-child(" + self.place + ") a").text().trim();
        var query = this.el.val();

        if (!url && query && query.length >= 2) {
            url = "/search?q=" + query;
            name = query.trim();
        }

        var save = {name: name, url: url};

        var suggests = localStorage.getItem('suggesteddata');
        var suggestData = JSON.parse(suggests);
        var saveSearch = 1;

        if (!suggestData) {
            suggestData = [];
        }
        else {
            for (var i = 0; i < suggestData.length; i++) {
                console.log(suggestData[i].url + " " + url)
                if (suggestData[i].url == url) {
                    saveSearch = 0;
                    // ALLREADY ADDED
                    console.log("ALLREADY added");

                }
            }
        }

        if (saveSearch == 1) {
            suggestData.unshift(save);
            if (suggestData.length > 5) {
                suggestData.splice(-1, 1);

            }
            localStorage.setItem('suggesteddata', JSON.stringify(suggestData));

        }
        if (url) {
            window.location.replace(url);

        }
    },

    suggestSearches: function (event) {
        var self = this;
        var sw = this.el.val();
        sw = sw.toLowerCase();
        var lastsearch = "";
        var inprogress = "";
        var e = event;

        if (sw.length >= 3) {
            var cats = [];
            var subcats = [];
            var reqData = {searchterm: sw}

            // EXEC
            if (lastsearch != sw && inprogress == 0) {
                inprogress = 1;
                lastsearch = sw;

                $.getJSON("/api/search/"+sw, null, function (d) {
                    inprogress = 0;
                    $("#" + self.suggestId).html(d.html).addClass("display");
                    $(".search-suggest").addClass("display");
                    self.count = $("#" + self.suggestId + " li").length;
                    self.place = 0;

                    console.log(d);

                });
            }
        }
        else if (sw.length <= 2) {
            console.log("do hide");

        }
    }
}

module.exports = searchSuggestView;