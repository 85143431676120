
var _resendPwView = (function() {
	var _inst = {};

	var apiService = require('../../services/apiService.js');

	var elements = {},
		$rootElement = null;

	_inst.init = () => {

		$rootElement = $('.signup-fullpage');
		elements.$emailField = $rootElement.find('#email');
		elements.$emailErrorfield = $rootElement.find('.input-error-msg[for=email]');
		elements.$submitBtn = $rootElement.find("input[type=submit]");

		_inst.bindUI();
	}

	_inst.bindUI = () => {
		elements.$submitBtn.on('click', function(e) {
			e.preventDefault();
			e.stopImmediatePropagation();

			var $this = $(this),
				email = elements.$emailField.val();

			if (!email) { return; } // guard

			$this.attr('data-loading', true);
			apiService.resendPassword(email)
				.then((resp) => {
                    elements.$emailField.removeClass('error');
                    elements.$emailErrorfield.removeClass('active');

					if (resp.status) {
                        window.location.href = resp.data.redirect;
					}
				})
				.catch((err) => {
                    $this.attr('data-loading', false);
					elements.$emailErrorfield.html("Emailen du angav finns inte vår databas.");
					elements.$emailField.addClass('error');
					elements.$emailErrorfield.addClass('active');
				});
		});


	}

	return _inst;
})();

module.exports = _resendPwView;