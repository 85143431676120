// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"campaigns") : depth0)) != null ? lookupProperty(stack1,"campaign") : stack1),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":1},"end":{"line":29,"column":10}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n			<div class=\"featured_campaign\">\n				\n					<p class=\"intro\">Aktuell kampanj i butiken</p>\n\n					<h2>"
    + alias4(((helper = (helper = lookupProperty(helpers,"subtitle") || (depth0 != null ? lookupProperty(depth0,"subtitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subtitle","hash":{},"data":data,"loc":{"start":{"line":9,"column":9},"end":{"line":9,"column":21}}}) : helper)))
    + "</h2>\n					\n					<h1>"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":11,"column":9},"end":{"line":11,"column":18}}}) : helper)))
    + "</h1>\n\n					<p>"
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":13,"column":23}}}) : helper)))
    + "</p>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"promocode") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":5},"end":{"line":20,"column":12}}})) != null ? stack1 : "")
    + "					\n					<a href=\"/go/product/"
    + alias4(container.lambda((depths[1] != null ? lookupProperty(depths[1],"productid") : depths[1]), depth0))
    + "\" target=\"_blank\">\n						<button class=\"btn btn-success btn-square btn-icon btn-large btn-icon-link\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"promocode") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":23,"column":82},"end":{"line":23,"column":150}}})) != null ? stack1 : "")
    + " </button>\n					</a>\n					<h4>"
    + alias4(((helper = (helper = lookupProperty(helpers,"days_left") || (depth0 != null ? lookupProperty(depth0,"days_left") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"days_left","hash":{},"data":data,"loc":{"start":{"line":25,"column":9},"end":{"line":25,"column":24}}}) : helper)))
    + "</h4>\n				\n			</div>\n		\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<div class=\"code\">\n							ANGE KOD : <span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"promocode") || (depth0 != null ? lookupProperty(depth0,"promocode") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"promocode","hash":{},"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":17,"column":39}}}) : helper)))
    + "</span>\n						</div>\n\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " Till rabattkoden ";
},"7":function(container,depth0,helpers,partials,data) {
    return " Till kampanjen ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"campaigns") : depth0)) != null ? lookupProperty(stack1,"campaign") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":30,"column":7}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});
