// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"policy__modal\" data-state=\"closed\">\n    <div class=\"container modal-container\">\n        <div class=\"policy__container\">\n            <div class=\"policy__header\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Privacy policy",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":20},"end":{"line":5,"column":43}}}))
    + "</h2>\n            </div>\n            <div class=\"policy__content\">\n                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Policy:Modal:Text",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":43}}}))
    + "\n\n                <p>\n                    <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Read our",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":26},"end":{"line":11,"column":43}}}))
    + " <a href=\"/informationspolicy\" target=\"_blank\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Privacy policy",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":90},"end":{"line":11,"column":113}}}))
    + "</a></span>\n                    <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Read our",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":26},"end":{"line":12,"column":43}}}))
    + " <a href=\"/anvandarvillkor\" target=\"_blank\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Terms of use",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":87},"end":{"line":12,"column":108}}}))
    + "</a></span>\n                </p>\n            </div>\n            <div class=\"policy__footer\">\n                <button class=\"policy__submit-btn\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"I have taken part of the information",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":51},"end":{"line":16,"column":97}}}))
    + "</button>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});
