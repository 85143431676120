var followProductView = require('../follow/followProductView');
var signupViewFactory = require('../../components/login/signupView.js');
var slick = require('../../helpers/slick.js');
var _product = require('../../components/v2/product/product');

var homeView = {

	signupInstances: [],

	init : function(){
		this.render();
		// this.bindProductsUi();
		_product.init();
		this.bindUi();


	},

	render : function(){
		var parentSelector = $('#home_products');
		var slickTarget = $('.homebrowse');

		slickTarget.slick({
		  dots: false,
		  infinite: true,
		  speed: 500,
		  slidesToShow: 4,
		  slidesToScroll: 2,
		  autoplay: false,
		  autoplaySpeed: 5000,
		  pauseOnHover: false,
		  centerMode: false,
		  variableWidth: true
		});

        parentSelector.attr('data-loaded', true);

		setTimeout(function () {
            parentSelector.attr('data-show', true);
        }, 0);
	},

	bindProductsUi : function(containEl){
		var self = this;
//		followProductView.init();

		if (containEl === undefined || containEl == '') {
        	containEl = 'home_products';

        }

		$("#"+containEl+" a:not(.unprevent)").unbind().bind("click", function(el){
			el.preventDefault();
			// Router.navigate({'productData' : self.products[$(el.currentTarget).attr("id")] }, '', $(el.currentTarget).attr("href"));
			prevPage.push($(el.currentTarget).attr("href"));
			window.history.pushState({'productData' : self.products[$(el.currentTarget).attr("id")] }, '', $(el.currentTarget).attr("href"));
			return false;
		});
	},

	bindUi : function(){
		var self = this;
		var $loginRootElement = $('.splash__online__signup');
		if ($loginRootElement.length > 0) {
			var signup = signupViewFactory.create();
			signup.init($loginRootElement);
            this.signupInstances.push(signup);
		}

		var $loginRootElementStartpage = $('.signup-splash-contain');
		if ($loginRootElementStartpage.length > 0) {
			var signup = signupViewFactory.create();
			signup.init($loginRootElementStartpage);
            this.signupInstances.push(signup);
		}

		var $loginRootElementOnlineSplash = $('#register-splash');
        if ($loginRootElementOnlineSplash .length > 0) {
            var signup = signupViewFactory.create();
            signup.init($loginRootElementOnlineSplash);
            this.signupInstances.push(signup);
        }

		if($(".splash").hasClass("splash")){
			this.carousel();
		}

	},

	carousel : function(){
		// CREATE TOGGLE BUTTONS
		$(".splash-item").each(function(i, e){
			var active = (i == 0) ? "active" : "";
			$(".splash-toggler").append("<div class='toggle-item "+active+"' data-place='"+(i+1)+"'></div>")

		});

		var active = 1;
		var timer = setInterval(function() {
			active++;
			if(active > $(".splash-item").length){
				active = 1;

			}

			$(".toggle-item.active").removeClass("active");
			$(".splash-item.active").removeClass("active");
			$(".splash-item:nth-child("+active+")").addClass("active");
			$(".toggle-item:nth-child("+active+")").addClass("active");

		}, 5000);

		$(".toggle-item").bind("click", function(){
			$(".toggle-item.active").removeClass("active");
			active = $(this).attr("data-place");
			$(this).addClass("active");
			$(".splash-item.active").removeClass("active");
			$(".splash-item:nth-child("+active+")").addClass("active");
			clearInterval(timer);

		});
	}

}
module.exports = homeView;
