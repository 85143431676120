
var _facebookService = (function() {
    var _inst = {},
        _ = window._;

    var _clientData = require('../config/clientData');

    _inst.authToken = "";

    _inst.init = () => {
        var facebookId = _clientData.get('facebook.id');

        if (!facebookId) {
            console.warn('No facebookId provided.');

            return;
        }

        window.fbAsyncInit = function () {
            FB.init({
                appId: facebookId,
                version: 'v2.9',
                channelUrl: '/channel.html',
                status: true,
                cookie: true,
                xfbml: true
            });

            FB.getLoginStatus(function(response) {
                console.log("%c FACEBOOK", "background: #222; color: #bada55", response);

                if (response.authResponse) {
                    _inst.authToken = response.authResponse.accessToken;
                }
                // logga in automatiskt
            });
        };

        var d = document, js, id = 'facebook-jssdk', ref = d.getElementsByTagName('script')[0];
        if (d.getElementById(id)) {
            return;
        }
        js = d.createElement('script');
        js.id = id;
        js.async = true;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        ref.parentNode.insertBefore(js, ref);
    }

    _inst.login = () => {
        return new Promise((resolve, reject) => {

            FB.login(response => {
                if (response.authResponse) {
                    _inst.authToken = response.authResponse.accessToken;
                    resolve(response);
                } else {
                    reject(response);
                }
            }, { scope: 'public_profile,email' });

        });

    }

    return _inst;
})();

module.exports = _facebookService;