var _brandView = (function () {
    var _inst = {};

    // Dependencies
    var _brandFollow = require('../../brand/brandFollow');
    
    var _bindUi = () => {
        $('.brand-card__container').on('click', function (event) {
            if (!event.target.dataset.hasOwnProperty('click')) {
                return;
            }

            event.preventDefault();

            var clickValue = event.target.dataset.click,
                brandId = event.target.dataset.brandId,
                genderId = event.target.dataset.genderId;

            switch (clickValue) {
                case 'follow':
                    _brandFollow.followBrand(brandId, genderId);

                    break;
                case 'unfollow':
                    _brandFollow.unfollowBrand(brandId, genderId);

                    break;
            }

            var followingState = clickValue === 'follow';

            $(this).attr('data-following', followingState);
        });
    };
    
    _inst.init = () => {
        _bindUi();
    };

    return _inst;
})();

module.exports = _brandView;