// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h2 class=\"dialog__item__header\">Du följer nu <i>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"follow") : depth0)) != null ? lookupProperty(stack1,"brand_id") : stack1), depth0))
    + "</i></h2>\n<div class=\"dialog__item__content\">\n    <a class=\"content__cta\" href=\"/"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeurl") || (depth0 != null ? lookupProperty(depth0,"typeurl") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"typeurl","hash":{},"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":46}}}) : helper)))
    + "/v/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"follow") : depth0)) != null ? lookupProperty(stack1,"brand_id") : stack1), depth0))
    + "/\">\n        <div class=\"btn btn-default\">Visa produkterna &rarr;</div>\n    </a>\n</div>\n<div class=\"dialog__closebtn\"><svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 19.1 19.1\" xml:space=\"preserve\"><path d=\"M0.9,0.6c0.8-0.8,2-0.8,2.8,0l14.8,14.8c0.8,0.8,0.8,2,0,2.8s-2,0.8-2.8,0L0.9,3.4C0.1,2.6,0.1,1.4,0.9,0.6z\"></path><path d=\"M0.6,18.5c-0.8-0.8-0.8-2.1,0-2.9l15-15c0.8-0.8,2.1-0.8,2.9,0s0.8,2.1,0,2.9l-15,15C2.7,19.3,1.4,19.3,0.6,18.5z\"></path></svg></div>\n\n<!--\n<div class=\"dialog_message brand_message\">\n	<div class=\"alert alert-success\">\n		<h2>Du följer nu <i>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"follow") : depth0)) != null ? lookupProperty(stack1,"brand_id") : stack1), depth0))
    + " (BACKEND: Ändra så att brand slug kommer med. Även på länken under)</i>!<span class=\"close\"></span></h2>\n		<div class=\"inner\">\n			<a href=\"/"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeurl") || (depth0 != null ? lookupProperty(depth0,"typeurl") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"typeurl","hash":{},"data":data,"loc":{"start":{"line":14,"column":13},"end":{"line":14,"column":24}}}) : helper)))
    + "/v/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"follow") : depth0)) != null ? lookupProperty(stack1,"brand_id") : stack1), depth0))
    + "/\">\n				<div class=\"btn btn-default\">Visa produkterna &rarr;</div>\n			</a>\n		</div>\n	</div>\n</div>-->\n";
},"useData":true});
