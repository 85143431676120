
var _signupView = (function() {

    return function() {
        var _inst = {};

        var translationController = require('../../controllers/translationController.js');
        var apiService = require('../../services/apiService.js');
        var facebookService = require('../../services/facebookService.js');
        var addKidsTpl = require('../../templates/addKids.hbs');

        var elements = {},
            $rootElement = null;

        var _obj = {};

        _inst.init = (el) => {

            // console.log("SIGNUP:", el);

            $rootElement = el || $('.signup-form');

            elements.$interestsCheckboxes = $rootElement.find('.checkers');
            elements.$registerKids = $rootElement.find('.register-kids');
            elements.$registerKidsAddBtn = $rootElement.find('.register-kids .add');
            elements.$registerKidsAddedKids = $rootElement.find('.register-kids .addedkids');

            elements.$emailField = $rootElement.find('input[type=email]');
            elements.$emailErrorField = $rootElement.find('.input-error-msg[for=email]');

            elements.$registerBtn = $rootElement.find('button[name=signup]');
            elements.$facebookBtn = $rootElement.find('button[name=fbjoin]');

            _inst.bindUi();
        }

        _inst.injectObject = (obj) => {
            _obj = obj;
        };

        var _renderWindowHeightDependingOnNumOfKids = () => {
            var children = elements.$registerKidsAddedKids.children().length;
            $('.startpage.online .splash').css('min-height', 620 + children*100);
        }

        _inst.bindUi = () => {

            elements.$interestsCheckboxes.on('click', '.checkitem label', (e) => {
                $(e.currentTarget).prev('.customcheck').trigger('click');
            });

            elements.$interestsCheckboxes.on('click', '.checkitem .customcheck', (e) => {

                $(e.currentTarget).toggleClass('checked');

                // if ($(e.currentTarget).data('id') == "barn" || 3) {
                //     elements.$registerKids.toggleClass("hidden");
                //
                //     if (elements.$registerKids.hasClass('hidden')) {
                //         $('.startpage.online .splash').css('min-height', 620);
                //     } else {
                //         if (window.innerWidth < 768) {
                //             _renderWindowHeightDependingOnNumOfKids();
                //         }
                //     }
                // }
            });

            elements.$emailField.on('keyup', (e) => {
                if (e.keyCode === 13) {
                    elements.$registerBtn.trigger('click');
                }
            });

            elements.$registerKidsAddBtn.on('click', () => {
                elements.$registerKidsAddedKids.append(addKidsTpl());

                if (window.innerWidth < 768) {
                    _renderWindowHeightDependingOnNumOfKids();
                }
            });

            elements.$registerKidsAddedKids.on('click', '.remove', (e) => {
                e.stopImmediatePropagation();
                e.preventDefault();

                let $pElement = e.currentTarget.closest('.kid-contain');
                if ($pElement) {
                    $pElement.remove();

                    if (window.innerWidth < 768) {
                        _renderWindowHeightDependingOnNumOfKids();
                    }
                }
            });

            var getFormData = () => {
                // Checkboxes
                var isChecked = elements.$interestsCheckboxes
                    .find('.customcheck')
                    .map(function() {
                        return $(this).hasClass('checked') ? $(this).data('id') : null;
                    })
                    .get();

                var kids = [];
                if (isChecked.indexOf('barn') > -1) {
                    kids = elements.$registerKids.find('input.newkid')
                        .map(function() {
                            return $(this).val() || null;
                        })
                        .get();
                }

                // Fields
                let email = elements.$emailField.val();

                return {
                    kids: kids,
                    email: email,
                    interests: isChecked
                }
            }

            var redirect = function (interests) {
                var refreshRoutes = [
                    'browse:list:gender',
                    'browse:list:brands:brand',
                    'product:show'
                ];

                var route = window.current.route,
                    reload = false,
                    url = '/';

                if (refreshRoutes.indexOf(route) > -1) {
                    url = window.location.href;
                    reload = true;
                } else {
                    //
                    // Ugly code as hell due to multiple template-systems.
                    // Will refactor when we remove hbs-templates and go international.
                    //

                    if (interests.indexOf(1) > -1 || interests.indexOf('dam') > -1) {
                        url += translationController.get('Gender:Female:Slug');
                    } else if (interests.indexOf(2) > -1 || interests.indexOf('herr') > -1) {
                        url += translationController.get('Gender:Male:Slug');
                    } else if (interests.indexOf(3) > -1 || interests.indexOf('barn') > -1) {
                        url += translationController.get('Gender:Kids:Slug');
                    } else if (interests.indexOf(4) > -1 || interests.indexOf('hem') > -1) {
                        url += translationController.get('Gender:Interior:Slug');
                    } else {
                        url += translationController.get('Gender:Female:Slug');
                    }
                }

                var postChar = window.location.hash ? '&' : '#',
                    redirectPostfix = postChar + 'welcome=new';

                window.location.href = url + redirectPostfix;

                if (reload) {
                    window.location.reload();
                }
            };

            var _followItem = (callback) => {
                if(_obj.hasOwnProperty('brand')) {
                    apiService.followBrand(
                        _obj.brand.id,
                        _obj.brand.genderId
                    ).then(() => {
                       if(callback) callback();

                        return false;
                    });
                }

                if(_obj.hasOwnProperty('product')) {
                    apiService.followProduct(
                        _obj.product.id,
                    ).then(() => {
                        if(callback) callback();

                        return false;
                    });
                }

                if(callback) callback();
            };

            // Forms

            elements.$registerBtn.on('click', (e) => {
                e.preventDefault();
                e.stopImmediatePropagation();

                var formData = getFormData(),
                    $this = $(event.currentTarget);

                $this.attr('data-loading', true);
                apiService.register(formData.email, formData.interests, formData.kids)
                    .then(function(resp) {
                        if (resp.status) {
                            _followItem(function () {
                                redirect(formData.interests);
                            });
                        }
                    })
                    .catch(function(err) {
                        $this.attr('data-loading', false);

                        var errResp = err.hasOwnProperty('responseJSON') ? err.responseJSON : null;
                        console.log(errResp);
                        var message;
                        if (errResp.hasOwnProperty('error')) {
                            message = errResp.error.message;
                        } else {
                            // Generic error
                            message = translationController.get('Error:Generic');
                        }
                        elements.$emailErrorField.html(message);
                        elements.$emailField.addClass('error');
                        elements.$emailErrorField.addClass('active');
                    });
            });

            // FACEBOOK

            if (elements.$facebookBtn) {
                elements.$facebookBtn.on('click', function(e) {
                    e.preventDefault();
                    e.stopImmediatePropagation();

                    var formData = getFormData(),
                        $this = $(this);

                    $this.attr('data-loading', true);
                    if (facebookService.authToken) {

                        apiService.registerFacebook(facebookService.authToken, formData.interests, formData.kids)
                            .then((resp) => {
                                if (resp.status) {
                                    _followItem(function () {
                                        redirect(formData.interests);
                                    });
                                }
                            })
                            .catch((err) => {
                                $this.attr('data-loading', false);
                            });

                    } else {

                        facebookService.login()
                            .then(function(response) {
                                console.log(response);
                                apiService.registerFacebook(facebookService.authToken, formData.interests, formData.kids)
                                    .then((resp) => {
                                        if (resp.status) {
                                            _followItem(function () {
                                                redirect(formData.interests);
                                            });
                                        }
                                    })
                                    .catch((err) => {
                                        $this.attr('data-loading', false);
                                    });
                            })
                            .catch(function(response) {
                                $this.attr('data-loading', false);
                            });

                    }
                });
            }

        }

        return _inst;
    }

})();

module.exports = outlet.core.factorify(_signupView);
