
var outletView = {
	
	init : function(){
		this.bindUi();
		
	},

	bindUi: function(){
		var self = this;
		$("#outletsearch").bind('keyup', function(event){
			if( event.which != 0 && event.which != 40 && event.which != 38 && event.which != 13){
				self.filterSearch($(this).val());

			}
		});
	},

	filterSearch : function(query){
		
		if(query.length == 0){
			$(".outlet_list li").removeClass("hide");
			
		}
		else{
			
			$(".outlet_list li").addClass("hide");
			$(".outlet_list li").each(function(i) {
				var name = $(this).attr("data-name");

				if (name.match(RegExp(query, 'i'))) {
					$(this).removeClass("hide");

				}
			});

		}
	}
}
module.exports = outletView;