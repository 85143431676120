var _product = (() => {
    var _inst = {};

    // Dependencies
    var _apiService = require('../../../services/apiService');

    // Selectors
    var targetSelector = '.product';

    var _events = {
        init: () => {
            $(targetSelector).on('click', (event) => {
                event.preventDefault();
                event.stopImmediatePropagation();

                var id = $(event.currentTarget).attr('id');

                _apiService.getProduct(id).then((response) => {

                }).catch((error) => {
                    console.log('PRODUCt ERROR: ', error);
                });
            });
        }
    };

    _inst.init = () => {
        if ($(targetSelector).length > 0) {
            // _events.init();
        }
    };

    return _inst;
})();

module.exports = _product;