// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<a href=\"/go/product/"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":17,"column":26},"end":{"line":17,"column":32}}}) : helper)))
    + "/\">\n						<div  style=\"background-image:url("
    + alias4(((helper = (helper = lookupProperty(helpers,"img1") || (depth0 != null ? lookupProperty(depth0,"img1") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"img1","hash":{},"data":data,"loc":{"start":{"line":18,"column":40},"end":{"line":18,"column":48}}}) : helper)))
    + ")\" data-imagelarge=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"img1") || (depth0 != null ? lookupProperty(depth0,"img1") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"img1","hash":{},"data":data,"loc":{"start":{"line":18,"column":68},"end":{"line":18,"column":76}}}) : helper)))
    + "\" class=\"thumb ontemp productimage thumbnr1\"></div>\n					</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<a href=\"/go/product/"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":22,"column":26},"end":{"line":22,"column":32}}}) : helper)))
    + "/\">\n						<div style=\"background-image:url("
    + alias4(((helper = (helper = lookupProperty(helpers,"img2") || (depth0 != null ? lookupProperty(depth0,"img2") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"img2","hash":{},"data":data,"loc":{"start":{"line":23,"column":39},"end":{"line":23,"column":47}}}) : helper)))
    + ")\" data-imagelarge=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"img2") || (depth0 != null ? lookupProperty(depth0,"img2") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"img2","hash":{},"data":data,"loc":{"start":{"line":23,"column":67},"end":{"line":23,"column":75}}}) : helper)))
    + "\" class=\"thumb ontemp productimage thumbnr2\"/>\n					</a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<a href=\"/go/product/"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":27,"column":26},"end":{"line":27,"column":32}}}) : helper)))
    + "\">\n						<div style=\"background-image:url("
    + alias4(((helper = (helper = lookupProperty(helpers,"img3") || (depth0 != null ? lookupProperty(depth0,"img3") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"img3","hash":{},"data":data,"loc":{"start":{"line":28,"column":39},"end":{"line":28,"column":47}}}) : helper)))
    + ")\" data-imagelarge=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"img3") || (depth0 != null ? lookupProperty(depth0,"img3") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"img3","hash":{},"data":data,"loc":{"start":{"line":28,"column":67},"end":{"line":28,"column":75}}}) : helper)))
    + "\" class=\"thumb ontemp productimage thumbnr3\"/>\n					</a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<p>\n						"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"description","hash":{},"data":data,"loc":{"start":{"line":44,"column":6},"end":{"line":44,"column":21}}}) : helper)))
    + "\n					</p>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return " reducted ";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<span class=\"oldprice\">\n								( "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"priceold") || (depth0 != null ? lookupProperty(depth0,"priceold") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"priceold","hash":{},"data":data,"loc":{"start":{"line":62,"column":10},"end":{"line":62,"column":24}}}) : helper)))
    + " SEK )\n							</span>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div class=\"reduced-contain\">\n                        <div class=\"product-sale-badge active\">\n                            <div class=\"onsale-percent\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"onsale") || (depth0 != null ? lookupProperty(depth0,"onsale") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"onsale","hash":{},"data":data,"loc":{"start":{"line":70,"column":56},"end":{"line":70,"column":68}}}) : helper)))
    + "%</div>\n                            <div class=\"onsale-off\">\n                                <div class=\"onsale-off__container\">\n                                    <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"You save :x :x today",(depth0 != null ? lookupProperty(depth0,"reducted") : depth0),"SEK",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":73,"column":42},"end":{"line":73,"column":86}}}))
    + "</span>\n                                </div>\n                            </div>\n                        </div>\n					</div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "					<div class=\"tips-contain\">\n						<b>Produkten är ej prissänkt just nu.</b><br/>\n						&hearts; Spara produkten nedan för att bli meddelad när priset sänks.\n					</div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "/uploads/products/p"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data,"loc":{"start":{"line":95,"column":87},"end":{"line":95,"column":94}}}) : helper)))
    + ".jpg ";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"image") || (depth0 != null ? lookupProperty(depth0,"image") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"image","hash":{},"data":data,"loc":{"start":{"line":95,"column":107},"end":{"line":95,"column":118}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"product-header\">\n	<div class=\"close\">Tillbaks</div>\n	<div class=\"breadcrumb productcrumb desctop\" id=\"product_breadcrumbs\"> </div>\n	\n</div>\n<img src=\"\" id=\"preload\"/>\n<div class=\"product-container\">\n	<div class=\"container\">\n		<div class=\"product-image-contain\">\n				\n			<div class=\"image\">\n				<div class=\"images\">\n					<a href=\"/go/product/"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":13,"column":26},"end":{"line":13,"column":32}}}) : helper)))
    + "/\">\n						<div class=\"productimage\" style=\"background-image:url("
    + alias4(((helper = (helper = lookupProperty(helpers,"image") || (depth0 != null ? lookupProperty(depth0,"image") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data,"loc":{"start":{"line":14,"column":60},"end":{"line":14,"column":71}}}) : helper)))
    + ")\"  id=\"productimage\"/>\n					</a>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"img1") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":5},"end":{"line":20,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"img2") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":5},"end":{"line":25,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"img3") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":5},"end":{"line":30,"column":12}}})) != null ? stack1 : "")
    + "				</div>\n			</div>\n\n			\n		</div>\n		<div class=\"product-description\">\n\n			<div class=\"description\">\n\n				<h4>"
    + alias4(((helper = (helper = lookupProperty(helpers,"brand") || (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"brand","hash":{},"data":data,"loc":{"start":{"line":40,"column":8},"end":{"line":40,"column":19}}}) : helper)))
    + " </h4>\n				<h1> "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":41,"column":9},"end":{"line":41,"column":19}}}) : helper)))
    + "</h1>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":4},"end":{"line":46,"column":11}}})) != null ? stack1 : "")
    + "<!--\n				<div class=\"product-sizes\">\n					<a href=\"/go/product/"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":49,"column":26},"end":{"line":49,"column":32}}}) : helper)))
    + "\" target=\"_blank\">\n						Se tillgängliga storlekar\n					</a>\n\n				</div>\n-->\n				<div class=\"price-container\">\n				\n					<div class=\"price-contain\">\n						<span class=\"price "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"onsale") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":25},"end":{"line":58,"column":57}}})) != null ? stack1 : "")
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"price") || (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"price","hash":{},"data":data,"loc":{"start":{"line":58,"column":59},"end":{"line":58,"column":70}}}) : helper)))
    + " SEK</span>\n							\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"onsale") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":6},"end":{"line":64,"column":13}}})) != null ? stack1 : "")
    + "\n					</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"onsale") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":5},"end":{"line":78,"column":12}}})) != null ? stack1 : "")
    + "				</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"onsale") : depth0),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":4},"end":{"line":86,"column":15}}})) != null ? stack1 : "")
    + "\n				<a href=\"/go/product/"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":88,"column":25},"end":{"line":88,"column":31}}}) : helper)))
    + "\" target=\"_blank\">\n					<button type=\"button\" class=\"btn btn-success fullwidth btn-icon btn-large btn-icon-link\">\n						Köp för "
    + alias4(((helper = (helper = lookupProperty(helpers,"price") || (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"price","hash":{},"data":data,"loc":{"start":{"line":90,"column":14},"end":{"line":90,"column":25}}}) : helper)))
    + "kr här\n					</button>\n\n				</a>\n				\n				<button type=\"button\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":95,"column":30},"end":{"line":95,"column":38}}}) : helper)))
    + "\" data-img=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"imagelocal") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":95,"column":50},"end":{"line":95,"column":125}}})) != null ? stack1 : "")
    + "\" data-price=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"price") || (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"price","hash":{},"data":data,"loc":{"start":{"line":95,"column":139},"end":{"line":95,"column":150}}}) : helper)))
    + "\" class=\"followproduct btn-icon btn-large btn-icon-heart fullwidth btn btn-default\" aria-label=\"Left Align\" alt-text='Tillagd!' data-event=\"followproduct\">Spara produkt</button>\n\n				<div id=\"featured_campaign_product\"></div>\n\n			</div>\n\n			<div class=\"breadcrumb productcrumb mobile\" id=\"product_breadcrumbs\"> </div>\n			\n		</div>\n	</div>\n	<div class=\"products-related\">\n		<div class=\"related-label\">\n			<h3>Relaterade produkter</h3>\n		</div>\n		<div class=\"container\">\n			<div id=\"related_products\" class=\"browse-products\"></div>\n		</div>\n	</div>\n    <div class=\"product-container__close\">\n        <svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 19.1 19.1\" xml:space=\"preserve\"><path d=\"M0.9,0.6c0.8-0.8,2-0.8,2.8,0l14.8,14.8c0.8,0.8,0.8,2,0,2.8s-2,0.8-2.8,0L0.9,3.4C0.1,2.6,0.1,1.4,0.9,0.6z\"/><path d=\"M0.6,18.5c-0.8-0.8-0.8-2.1,0-2.9l15-15c0.8-0.8,2.1-0.8,2.9,0s0.8,2.1,0,2.9l-15,15C2.7,19.3,1.4,19.3,0.6,18.5z\"/></svg>\n    </div>\n</div>\n	\n";
},"useData":true});
