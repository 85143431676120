var productBrowserController = (() => {
    var _inst                 = {},
        _isInited             = false,
        _topCategoryInit      = false,
        _fromSubCategory      = false,
        _brandElements        = {},
        _priceRangeHasChanged = false,
        _priceRangeMax        = null;

    // Components
    var _product = require('../components/v2/product/product');
    var _signupViewFactory = require('../components/login/signupView.js');

    // Dependencies
    let _authController = require('./authController');

    // Services
    var apiService = require('../services/apiService');

    // General selectors
    var filterSelector            = '#filter',
        filterSerializeSelector   = '#filter, #sorter',
        paginatorNextSelector     = '#next',
        paginatorPreviousSelector = '#prev',
        paginatorItemSelector     = '.paginator-item',
        fetchLoadingSelector      = '.fetch-loader';

    // General
    var _validParameters       = [
            'p',
            'sort'
        ],
        _validFilterParameters = [
            'brands',
            'colors',
            'reducted',
            'max_price',
            'shops',
            'sizes',
            'welcome'
        ];

    // Api
    var _currentPage   = window.paginator.currentPage,
        _refreshFilter = false;

    // Functions
    var _getBrandElements = function () {
        _brandElements = $('#brands').find('li');
    };

    var _bindToggleUi = function () {
        var targetSelector         = '.filter_contain',
            toggleSelector         = 'h3',
            filterToggleSelector   = '.toggle-filter',
            categoryToggleSelector = '.toggle-categories',
            backlightsSelector     = '.backlights',
            closeFilterSelector    = '#close-filter';

        $(targetSelector).find(toggleSelector).on('click', function (event) {
            event.preventDefault();
            event.stopImmediatePropagation();

            var element = $(this);
            element.parent(targetSelector).toggleClass('closed');
        });

        $(filterToggleSelector).on("click", function () {
            $(filterSelector).removeClass("from-left");

            setTimeout(function () {
                $(filterSelector).addClass("display");
                $("#category_filter").addClass("closed");
            }, 10);
        });

        $(categoryToggleSelector).on("click", function () {

            $(filterSelector).addClass("from-left");

            setTimeout(function () {
                $(filterSelector).addClass("display");
                $("#category_filter").removeClass("closed");
            }, 10);
        });

        $(backlightsSelector).add(closeFilterSelector).on('click', function () {
            $(filterSelector).removeClass("display");
        });
    };

    var _filterBrands = function (query) {
        var targetSelector = '#brands';

        $(targetSelector).find('li').addClass('hide');

        $.each(_brandElements, function (key, element) {
            element = $(element);

            var brand = element.data('brand').toString();

            if (brand.indexOf(query) > -1) {
                element.removeClass('hide');
            }
        });
    };

    var _bindBrandSearchUi = function () {
        var targetSelector       = '#search_brand',
            brandsTargetSelector = '#brands';

        // Disable brand href
        $(document.body).on('click', '#brands a', function (event) {
            event.preventDefault();

            var input   = $(this).parent('label').prev('input'),
                checked = input.prop('checked');

            input.prop('checked', !checked).change();
        });

        $(document).on('input keyup keypress', targetSelector, function (event) {
            var key = event.keyCode || event.which;
            if (key === 13) {
                event.preventDefault();
                return false;
            }

            var $this = $(this);

            if ($this.val().length < 2) {
                $(brandsTargetSelector).find('li').removeClass('hide');

                return;
            }

            var query = $this.val().trim().toLowerCase();

            _filterBrands(query);
        });
    };

    var _bindPriceRangeUi = function () {
        var priceSelector = '#max_price';
        var targetSelector = '#max_price_range';

        $(document).on('input', targetSelector, function (event) {
            if (_priceRangeHasChanged === false) {
                _priceRangeHasChanged = true
            }

            var maxPrice = $(this).val();

            $(priceSelector).html(maxPrice);
        });

        _priceRangeMax = $(targetSelector).attr('max');
    };

    var _bindColorsUi = function () {
        var targetSelector   = '.color__checker',
            checkboxSelector = '.color__checkbox';

        $(document.body).on('click', targetSelector, function (event) {
            event.preventDefault();

            var $this = $(this);
            var id = $this.attr('data-target-id');

            var checkbox = $(checkboxSelector + "[data-target-id=" + id + "]");
            var checked = checkbox.prop("checked");

            checkbox.prop("checked", !checked).change();
            $this.attr('data-checked', !checked);

        });
    };

    var _bindReductedUi = function () {
        var targetSelector = "#reducted_filter li";

        $(document.body).on('click', targetSelector, function () {
            var $this     = $(this),
                allInputs = $(targetSelector).find('input'),
                input     = $this.find('input'),
                checked   = input.prop('checked');

            if ($this.attr('data-disabled') === 'true') {
                return;
            }

            allInputs.prop('checked', false);
            input.prop('checked', !checked).change();
        });
    };

    var _updateInputId = function (name, id, disable, triggerChange) {
        var input = $('input[name=' + name + ']');
        disable = disable || false;

        if (input.prop('disabled') === true && !disable) {
            input.prop('disabled', false);
        }

        input.attr('value', id);

        if (disable) {
            input.prop('disabled', true);
        }

        if (triggerChange) {
            input.change();
        }
    };

    var _bindCategories = function () {

        // Parents
        $('.categories__name').on('click', function (event) {
            event.preventDefault();

            _refreshFilter = true;

            var $this    = $(this),
                genderId = $this.attr('data-gender-id'),
                url      = $this.attr('href');

            $('.categories__subcategories').attr('data-show', false);
            $('.subcategories__children').attr('data-show', false);
            $('.subcategories__item')
                .attr('data-show', false)
                .attr('data-active', false);
            $('.children__item').attr('data-active', false);

            $this
                .next('.categories__subcategories')
                .attr('data-show', true)
                .find('.subcategories__item')
                .attr('data-show', true);

            _updateUrl(url);
            _updateInputId('gender_id', genderId, false);
            _updateInputId('category_id', 0, true, true);

            if (!_topCategoryInit) {
                _inst.setTopCategoryInit(true);
            }
        });

        // Subcategories
        $('.subcategories__name').on('click', function (event) {
            event.preventDefault();

            _fromSubCategory = true;
            _refreshFilter = true;

            var $this      = $(this),
                categoryId = $this.attr('data-target-id'),
                url        = $this.attr('href');

            _updateUrl(url);
            _updateInputId('category_id', categoryId, false, true);

            $('.subcategories__name')
                .parent('.subcategories__item')
                .attr('data-show', false);

            $this.parent('.subcategories__item')
                .attr('data-show', true)
                .attr('data-active', true);

            $this.parent('.subcategories__item')
                .find('.subcategories__children')
                .attr('data-show', true);

            $('.children__item').attr('data-active', false);
        });

        // Children
        $('.children__item').on('click', function (event) {
            event.preventDefault();

            var $this      = $(this),
                categoryId = $this.attr('data-target-id');

            if (!_fromSubCategory) {
                _refreshFilter = true;
            }

            _updateInputId('category_id', categoryId, false, true);
            _updateUrl($this.attr('href'));

            $('.children__item').attr('data-active', false);

            $(this).attr('data-active', true);
        });

        // Show all
        /*$('.categories__show-all').on('click', function () {
            _refreshFilter = true;

            var $this = $(this);

            $('.subcategories__children').attr('data-show', false);
            $('.subcategories__item')
                .attr('data-show', false)
                .attr('data-active', false);
            $('.children__item').attr('data-active', false);


            $this.parents('.categories__subcategories')
                .find('.subcategories__item')
                .attr('data-show', true);

            _updateInputId('category_id', 0, true);
            _updateUrl($('.categories__name').attr('href'));

            if (!_topCategoryInit) {
                _inst.setTopCategoryInit(true);
            }
        });*/
    };

    var _bindFilterUi = function () {
        _bindToggleUi();
        _bindBrandSearchUi();
        _bindReductedUi();
        _bindPriceRangeUi();
        _bindColorsUi();
        _bindCategories();
    };

    var _transformSerializeData = function (serializeData) {
        var transformedData   = {},
            specialParameters = [
                'max_price',
                'sort',
                'search_brand'
            ];

        $.each(serializeData, function (key, item) {
            var isArray = false,
                value   = item.value,
                name    = item.name;

            if (specialParameters.indexOf(name) > -1) {

                switch (name) {
                    case 'max_price':

                        if (!_priceRangeHasChanged || value == _priceRangeMax) {
                            return;
                        }

                        break;
                    case 'sort':
                        if (_authController.isLoggedIn()) {
                            if (value === 'personal') {
                                return;
                            }

                            break;
                        }

                        if (value === 'popular') {
                            return;
                        }

                        break;
                    default:
                        return;
                }
            }

            if (name.indexOf('[]') > -1) {
                isArray = true;
                name = name.replace('[]', '');
            }

            if (transformedData.hasOwnProperty(name)) {
                transformedData[name].push(value);

                return;
            }

            transformedData[name] = isArray ? [value] : value
        });

        return transformedData;
    };

    var _getFilterData = function () {
        return _transformSerializeData(
            $(filterSerializeSelector).serializeArray()
        );
    };

    var _generateQueryStringFromData = function (data) {
        var parts = [];

        for (var key in data) {
            if (!data.hasOwnProperty(key) || (_validParameters.indexOf(key) === -1 && _validFilterParameters.indexOf(key) === -1)) {
                continue;
            }

            var item = data[key],
                part;

            if (typeof item === 'string' || typeof item === 'number') {
                part = key + '=' + item;
                parts.push(part);

                continue;
            }

            var values = item.join(',');
            part = key + '=' + values;

            parts.push(part);
        }

        return parts.join('&');
    };

    var _updateUrl = function (pathName, hashString, triggerPageView) {
        var baseUrl = [location.protocol, '//', location.host].join(''),
            newUrl  = baseUrl;

        // Path
        newUrl += pathName ? pathName : location.pathname;

        // Params
        if (window.location.search) {
            newUrl = newUrl.replace(/\/$/, "");
            newUrl += window.location.search;
        }

        // Hash
        newUrl += (hashString !== '' && typeof hashString !== 'undefined')
            ? '#' + hashString
            : (typeof hashString === 'undefined' && location.hash.length > 1 ? location.hash : '');

        // Update
        window.history.replaceState({}, "", newUrl);

        if (triggerPageView) {
            // Send a new page view to Google Analytics
            ga('send', 'pageview', newUrl);
        }
    };

    var _fetchLoading = function (value) {
        $(fetchLoadingSelector).attr('data-loading', value);
    };

    var _updateBoxFilter = function (data, target, attribute, sizeCompare) {
        var targetSelectors = $(target);

        // Instead of checking if value exists, check if value is bigger than x
        sizeCompare = sizeCompare || false;

        targetSelectors.attr('data-disabled', false);

        targetSelectors.each(function () {
            var $this        = $(this),
                elementValue = $this.attr(attribute);

            if (sizeCompare) {
                if (data < elementValue) {
                    $this.attr('data-disabled', true);
                }

                return;
            }

            if (data.indexOf(parseInt(elementValue, 10)) === -1) {
                $this.attr('data-disabled', true);
            }
        });
    };

    var _updateMaxPrice = function (priceData) {
        var maxPriceTarget      = '#max_price',
            maxPriceRangeTarget = "#max_price_range";

        var hasCurrent = priceData.current !== null,
            priceMax   = Math.ceil(priceData.max / 500) * 500,
            priceValue = hasCurrent && priceData.current < priceMax ? priceData.current : priceMax;

        $(maxPriceTarget).html(priceValue);
        $(maxPriceRangeTarget)
            .prop('max', priceMax)
            .prop('value', priceValue);

        _priceRangeMax = priceMax;
    };

    var _updateProducts = function (products, count) {
        var productsTarget = '#browse_products',
            countTarget    = "#totalproducts";

        $(productsTarget).html(products);
        $(countTarget).html(count);
    };

    var _updateMeta = function (meta) {
        // Breadcrumb
        if (meta.hasOwnProperty('breadcrumbs')) {
            $('#breadcrumbs').html(meta.breadcrumbs);
        }

        if (meta.hasOwnProperty('title')) {
            $('.hero').find('h1').html(meta.title);
        }

        // Hide SEO
        $('.intro').css('display', 'none');
    };

    var _updateGenders = function (categoryIds) {
        $('.categories__name').each(function () {
            var $this = $(this);

            var categories = $this
                .next('.categories__subcategories')
                .find('.subcategories__name');

            var elementIds = categories.map(function () {
                return parseInt($(this).attr('data-target-id'));
            }).get();

            var intersected = categoryIds.filter(function (value) {
                return elementIds.indexOf(value) !== -1;
            });

            var state = intersected.length === 0;
            $this.attr('data-disabled', state);
            $this.next('.categories__subcategories')
                .attr('data-show', !state);
        });
    };

    var _updateUi = function (response) {
        // Paginator
        _checkPaginator(response.paginator);

        // Products
        _updateProducts(response.data.products, response.paginator.items);

        // Meta
        if (response.data.hasOwnProperty('meta')) {
            _updateMeta(response.data.meta);
        }

        var filter = response.data.filter;

        // Categories
        _updateBoxFilter(
            filter.categories,
            '.subcategories__name, .children__item',
            'data-target-id'
        );

        // Genders
        if (filter.hasOwnProperty('categoriesNoGender')) {
            _updateGenders(filter.categoriesNoGender);
        }

        // Brands
        if (response.data.hasOwnProperty('brands')) {
            $('#brands')
                .find('ul')
                .html(response.data.brands);

            _getBrandElements();
        } else {
            _updateBoxFilter(
                filter.brands,
                '#brands li',
                'data-target-id'
            );
        }

        // Reducted

        if (response.data.hasOwnProperty('reduced')) {
            $('#reducted_filter')
                .find('ul')
                .html(response.data.reduced);
        } else {
            var maxValue = filter.reduced.length === 0
                ? 0
                : Math.max.apply(Math, filter.reduced.map(val => {
                    return parseInt(val);
                }));

            _updateBoxFilter(
                maxValue,
                '#reducted_filter li',
                'data-target-id',
                true
            );
        }

        // Max price
        _updateMaxPrice(filter.price);

        // Shops
        if (response.data.hasOwnProperty('shops')) {
            $('#shop_filter')
                .find('ul')
                .html(response.data.shops);
        } else {
            _updateBoxFilter(
                filter.shops,
                '#shop_filter li',
                'data-target-id'
            );
        }

        // Colors
        if (response.data.hasOwnProperty('colors')) {
            $('#color_filter')
                .find('ul')
                .html(response.data.colors);
        } else {
            _updateBoxFilter(
                filter.colors,
                '#color_filter li',
                'data-target-id'
            );
        }

        // Sizes
    };

    var _getHashData = function () {
        var hashData = window.location.hash
            .substr(1)
            .split('&')
            .map(el => el.split('='))
            .reduce((pre, cur) => {
                var values = cur[1].split(',');

                if (values.length === 1) {
                    values = values[0];
                }

                pre[cur[0]] = values;

                return pre;
            }, {});


        return hashData;
    }

    var _openSelectedToggle = function (data) {
        for (var key in data) {
            if (_validFilterParameters.indexOf(key) === -1) {
                continue;
            }

            $('div[data-filter-id=' + key + ']').removeClass('closed');
        }
    };

    var _fetchFromApi = function (callback, mergeData) {
        var data = _getFilterData();
        mergeData = mergeData || false;

        if (mergeData) {
            Object.assign(data, mergeData);
        }

        if (_currentPage > 1) {
            Object.assign(data, {
                p: _currentPage
            });
        }

        if (_refreshFilter && !_topCategoryInit) {
            Object.assign(data, {
                refresh: true
            })
        }

        _refreshFilter = false;

        // Add loader
        _fetchLoading(true);

        apiService.productBrowse(data).then((response) => {
            if (response.hasOwnProperty('paginator')) {
                var paginator = response.paginator;

                _currentPage = paginator.current;
            }

            // Regenerate url
            _updateUrl(null, _generateQueryStringFromData(data), true);

            // Open selected toggles
            _openSelectedToggle(data);

            if (callback) {
                callback(response);
            }
        });
    };

    var _bindFormToFetch = function () {
        var ignoreChangeTarget = [
            'search_brand'
        ];

        $(document).on('change', filterSerializeSelector, function (event) {
            if (ignoreChangeTarget.indexOf(event.target.name) > -1) {
                return false;
            }

            _currentPage = 1;
            _fetchFromApi((response) => {
                _updateUi(response);
                _fetchLoading(false);
            });
        });
    };

    var _checkPaginator = function (paginator) {
        if (!paginator.url.hasOwnProperty('next')) {
            $(paginatorNextSelector).addClass('disabled')
        } else {
            if ($(paginatorNextSelector).hasClass('disabled')) {
                $(paginatorNextSelector).removeClass('disabled')
            }
        }

        if (!paginator.url.hasOwnProperty('prev')) {
            $(paginatorPreviousSelector).addClass('disabled')
        } else {
            if ($(paginatorPreviousSelector).hasClass('disabled')) {
                $(paginatorPreviousSelector).removeClass('disabled')
            }
        }

        _enablePaginatorEvent();
    };

    var _disablePaginatorEvent = function () {
        $(paginatorItemSelector).attr('data-loading', true);
    };

    var _enablePaginatorEvent = function () {
        $(paginatorItemSelector).attr('data-loading', false)
    };

    var _bindPagination = function () {
        $(paginatorNextSelector).add(paginatorPreviousSelector).on('click', function (event) {
            event.preventDefault();
            event.stopImmediatePropagation();

            _currentPage = event.currentTarget.id === 'prev'
                ? _currentPage === 1 ? 1 : _currentPage - 1
                : _currentPage + 1;

            _disablePaginatorEvent();

            _fetchFromApi((response) => {
                $('html, body').animate({
                    scrollTop: 0
                }, {
                    duration: 500,
                    complete: () => {
                        _updateUi(response);

                        setTimeout(function () {
                            _fetchLoading(false);
                        }, 200);
                    }
                });
            });
        });
    };

    var _setCheckedOnHashData = function (hashData) {
        $.each(hashData, (key, values) => {
            var targetSelector;

            if (typeof values !== 'object') {
                values = [values];
            }

            switch (key) {
                case 'colors': {
                    targetSelector = $('input[data-target=' + key + ']')
                        .add('span[data-target=' + key + ']');

                    break;
                }

                default: {
                    targetSelector = $('input[data-target=' + key + ']');

                    break;
                }
            }

            targetSelector.each(function () {
                var $this = $(this);

                if (values.indexOf($this.attr('data-target-id')) > -1) {
                    if (!$this.is('input')) {
                        $this.attr('data-checked', true);

                        return;
                    }

                    $this.attr('checked', true);
                }
            });
        });
    };

    var _setSelectedOnSort = function (sort) {
        var targetSelector = $('#sorter');

        // Reset
        targetSelector
            .find('option')
            .attr('selected', false);

        // Select
        targetSelector
            .find('option[value=' + sort + ']')
            .attr('selected', true);
    };

    var _bindUi = function () {
        var $signupRootElement = $('.signupbrowse');

        if ($signupRootElement.length > 0) {
            var signup = _signupViewFactory.create();
            signup.init($signupRootElement);
        }
    };

    _inst.isInited = function () {
        return _isInited;
    };

    _inst.setTopCategoryInit = function (state) {
        _topCategoryInit = state;
    };

    _inst.init = function (topCategoryInit) {
        _isInited = true;
        _product.init();
        topCategoryInit = topCategoryInit || false;

        if (topCategoryInit) {
            _inst.setTopCategoryInit(true);
        }

        if (window.location.hash && window.location.hash !== '#welcome=new') {
            var hashData = _getHashData();

            _fetchFromApi((response) => {
                _updateUi(response);
                _setCheckedOnHashData(hashData);

                if (hashData.hasOwnProperty('sort')) {
                    _setSelectedOnSort(hashData.sort)
                }

                setTimeout(function () {
                    _fetchLoading(false);
                }, 100);
            }, hashData);
        }

        _getBrandElements();
        _bindPagination();
        _bindFilterUi();
        _bindFormToFetch();
        _bindUi();
    };

    return _inst;
})();

module.exports = productBrowserController;
