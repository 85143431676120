// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <h3>\n                            <b>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Pricewatch",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":31},"end":{"line":9,"column":49}}}))
    + ":</b> "
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"product") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n                        </h3>\n                        <span>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"We will notify you when the price is lowered below",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":28},"end":{"line":12,"column":87}}}))
    + " <b>"
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"product") : depth0)) != null ? lookupProperty(stack1,"price") : stack1), depth0))
    + " "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Currency:Short",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":111},"end":{"line":12,"column":133}}}))
    + "</b>\n                        </span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"brand") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":21,"column":20}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <h3>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Follow sales & campaigns from",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":28},"end":{"line":16,"column":65}}}))
    + " <b>"
    + alias3(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</b>\n                        </h3>\n                        <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Never miss a campaign on :x again",((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":30},"end":{"line":18,"column":83}}}))
    + "</span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <h3>"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Create account. Never miss a discount again.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":28},"end":{"line":20,"column":81}}}))
    + "</h3>\n                    ";
},"8":function(container,depth0,helpers,partials,data) {
    return "\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"brand") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":34,"column":20}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <h3>"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"What products interest you?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":28},"end":{"line":33,"column":64}}}))
    + "</h3>\n                    ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"signup__modal\" data-state=\"closed\">\n    <section class=\"container signup-modal modal-container\">\n        <div class=\"signup-splash\">\n            <div class=\"signup-pros\">\n                <div class=\"inner\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"product") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":21,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n\n            </div>\n            <div class=\"signup-form\">\n\n                <div class=\"inner\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"product") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":28,"column":20},"end":{"line":34,"column":27}}})) != null ? stack1 : "")
    + "\n                    <div class=\"checkers\">\n                        <div class=\"checkitem\">\n                            <div class=\"customcheck\" data-id=\"dam\"></div><label>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Category:Female:Long",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":80},"end":{"line":38,"column":109}}}))
    + "</label>\n                        </div>\n                        <div class=\"checkitem\">\n                            <div class=\"customcheck\" data-id=\"herr\"></div><label>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Category:Male:Long",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":81},"end":{"line":41,"column":108}}}))
    + "</label>\n                        </div>\n                        <div class=\"checkitem\">\n                            <div class=\"customcheck\" data-id=\"barn\"></div><label>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Category:Kids:Long",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":81},"end":{"line":44,"column":108}}}))
    + "</label>\n                        </div>\n                        <div class=\"checkitem\">\n                            <div class=\"customcheck\" data-id=\"hem\"></div><label>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Category:Interior",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":80},"end":{"line":47,"column":106}}}))
    + "</label>\n                        </div>\n                    </div>\n\n                    <div class=\"register-kids hidden\">\n                        <h3>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Childs birthdate (not required)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":28},"end":{"line":52,"column":68}}}))
    + "</h3>\n                        <div class=\"addedkids\">\n                            <div class=\"kid-contain\">\n                                <input type=\"text\" class=\"newkid \" placeholder=\"YYYY-MM-DD\">\n                                <div class=\"remove\"><svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 19.1 19.1\" xml:space=\"preserve\"><path d=\"M0.9,0.6c0.8-0.8,2-0.8,2.8,0l14.8,14.8c0.8,0.8,0.8,2,0,2.8s-2,0.8-2.8,0L0.9,3.4C0.1,2.6,0.1,1.4,0.9,0.6z\"></path><path d=\"M0.6,18.5c-0.8-0.8-0.8-2.1,0-2.9l15-15c0.8-0.8,2.1-0.8,2.9,0s0.8,2.1,0,2.9l-15,15\n        C2.7,19.3,1.4,19.3,0.6,18.5z\"></path></svg></div>\n                            </div>\n                        </div>\n                        <span class=\"add\">+ "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Add more",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":44},"end":{"line":60,"column":61}}}))
    + "</span>\n                    </div>\n\n                    <input type=\"email\" name=\"email\" class=\"email input-large\" placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"E-mail",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":92},"end":{"line":63,"column":106}}}))
    + "\">\n                    <label class=\"input-error-msg\" for=\"email\"></label>\n\n                    <button type=\"submit\" class=\"btn btn-square btn-success submit\" data-loading=\"false\" name=\"signup\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Register now",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":119},"end":{"line":66,"column":140}}}))
    + "</button>\n\n                </div>\n\n                <div class=\"form__or\">\n                    <h4><span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"or",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":71,"column":30},"end":{"line":71,"column":41}}}))
    + "</span></h4>\n                </div>\n\n                <div class=\"fb-join inner\">\n                    <button type=\"submit\" class=\"btn btn-square btn-success btn-fb\" name=\"fbjoin\" data-loading=\"false\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Register with facebook",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":75,"column":119},"end":{"line":75,"column":150}}}))
    + "</button>\n                </div>\n\n                <p class=\"signup__policy\">\n                    <!--"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Already registered?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":79,"column":24},"end":{"line":79,"column":52}}}))
    + " <a href=\"/login/\" data-event=\"loginmodal\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Login",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":79,"column":95},"end":{"line":79,"column":109}}}))
    + "</a>-->\n                    "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Take part of our",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":80,"column":20},"end":{"line":80,"column":45}}}))
    + " <a href=\"/informationspolicy\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Privacy policy",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":80,"column":76},"end":{"line":80,"column":99}}}))
    + "</a> "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"and",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":80,"column":104},"end":{"line":80,"column":116}}}))
    + " <a href=\"/anvandarvillkor\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Terms of use",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":80,"column":144},"end":{"line":80,"column":165}}}))
    + "</a>\n                </p>\n\n            </div>\n        </div>\n\n        <div class=\"closebtn\">\n            <svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 19.1 19.1\" xml:space=\"preserve\"><path d=\"M0.9,0.6c0.8-0.8,2-0.8,2.8,0l14.8,14.8c0.8,0.8,0.8,2,0,2.8s-2,0.8-2.8,0L0.9,3.4C0.1,2.6,0.1,1.4,0.9,0.6z\"\n			/><path d=\"M0.6,18.5c-0.8-0.8-0.8-2.1,0-2.9l15-15c0.8-0.8,2.1-0.8,2.9,0s0.8,2.1,0,2.9l-15,15\n			C2.7,19.3,1.4,19.3,0.6,18.5z\"/></svg>\n        </div>\n    </section>\n</div>\n\n\n";
},"useData":true});
