var showProductsTpl = require('../../templates/showProduct.hbs');
var followProductView = require('../follow/followProductView');
var featuredProductCampaignTpl = require('../../templates/featuredProductCampaign.hbs');
var listProductsTpl = require('../../templates/listProducts.hbs');
var extraImagesTpl = require('../../templates/extraImages.hbs');
var breadCrumbsTpl = require('../../templates/breadcrumbs.hbs');
var previousProducts = require('./previousProducts');

var productView = {

    data: {},

    direct: 0,

    el: $("#show_product"),

    init: function (productData, direct) {
        this.data = productData;
        this.direct = direct;

        if (productData) {
            this.render();
        }
    },

    render: function () {
        if (this.direct != 1) {
            this.el.html(showProductsTpl(this.data));
            this.showView();

        }

        this.getRelatedContent();
        this.bindUi();
        this.updateBreadCrumbs();
        this.saveToPrevious();

    },

    showView: function () {
        this.el.addClass("display");
        this.setYOffset();

    },

    hideView: function () {
        this.el.removeClass("display");
        $("body").removeClass("noscroll");
        $("#followcurrent").removeClass("active");

    },

    close: function () {
        window.history.back();

    },

    setYOffset: function () {
        $("body").addClass("noscroll");
        $("#toggle_menu").removeClass("active");
        $("nav, ul.nav, .search-mobile-container").removeClass("display");
        $("#profile-mobile").removeClass("display");

    },

    bindUi: function () {
        var self = this;
        followProductView.init();
        // BIND NAV BUTTONS
        $(".product-container__close, .close, #backbutton").unbind().bind("click", function () {
            self.close();
        });
        $("#followcurrent").unbind().bind("click", function () {
            // PROXY CLICK
            $("#show_product button.followproduct#" + self.data.id).click();
            $(this).addClass("active");
        });
        if (this.direct == 1) {
            $(".close, #backbutton").hide();
        }
    },

    getRelatedContent: function () {

        var self = this;
        $.get('/api/product/related/', {
            'id': this.data.id,
            'shop': this.data.shop,
            'gender': this.data.gender,
            'type': this.data.type,
            'img1': this.data.img1
        })
            .done(function (response) {

                    if (response.campaigns.campaign.length >= 1) {
                        $("#featured_campaign_product").html(featuredProductCampaignTpl(response)).addClass("display");

                    }
                    if (response.products.data.length >= 1) {
                        $("#related_products").html(listProductsTpl(response)).addClass("display");

                    }

                    if (response.images.length > 1) {
                        var image = document.getElementById("preload");
                        var imageUrl = response.images[0].imageLarge;
                        var downloadingImage = new Image;

                        function loadimage() {
                            $("#productimage").css("background-image", "url(" + imageUrl + ")");

                        }

                        downloadingImage.onload = loadimage;
                        downloadingImage.src = imageUrl;

                        var extras = response.images.shift();
                        self.bindImagesUi();


                        $.each(response.images, function (key, val) {
                            $(".images").slick('slickAdd', "<a href='/go/product/'" + self.data.id + "/'><div style='background-image:url(" + val.imageLarge + ");' class='thumb productimage thumbnr" + (key + 1) + "'></a>");
                            $(".images .thumbnr" + (key + 1)).addClass("full");

                        });
                        $(".images .thumb.ontemp").addClass("full");
                    }
                }
            );
    },

    bindImagesUi: function () {

        $(".images").slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 5000,
            pauseOnHover: false,
            centerMode: false,
            variableWidth: true,

        });

        //$(".images .thumb").addClass("full");
    },

    updateBreadCrumbs: function () {
        var breads = [];
        var baseurl = "dam";
        console.log("breaddata : type: " + this.data.type + " gender: " + this.data.gender);
        if (this.data.type != 0 && this.data.type != null) {
            baseurl = (this.data.type == 1) ? "barn" : "hem";
            breads.push({url: "/" + baseurl + "/", name: baseurl});

        }
        if (this.data.gender && (this.data.type == "" || this.data.type == null)) {
            baseurl = (this.data.gender == 2) ? "herr" : "dam";
            breads.push({url: "/" + baseurl + "/", name: baseurl});

        }
        if (this.data.category) {
            breads.push({url: "/" + baseurl + "/" + this.data.category + "/", name: this.data.category});
        }
        if (this.data.subcategory) {
            breads.push({
                url: "/" + baseurl + "/" + this.data.category + "/" + this.data.subcategory + "/",
                name: this.data.subcategory
            });
        }
        if (this.data.brand != "") {
            breads.push({url: "/" + baseurl + "/v/" + encodeURI(this.data.brand) + "/", name: this.data.brand});
        }
        breads.push({url: location.href, name: this.data.name});

        $(".productcrumb").html(breadCrumbsTpl({breads: breads}));

    },

    saveToPrevious: function () {
        previousProducts.save(this.data.id);

    },

    checkBackButton: function () {
        var prevLength = (prevPage.length >= 1) ? (prevPage.length - 1) : 0;
        if (prevPage[prevLength] && prevPage[prevLength].indexOf("product/") !== -1) {
            prevPage.remove(-1);
            productView.hideView();
            return false;

        }
        else {
            return true;

        }
    }

}
module.exports = productView;
