
var signupViewFactory = require('../login/signupView');

var footerView = {
    init : function(){
        this.bindUi();
    },

    bindUi : function(){

        var $signupFooter = $('.signup__footer');
        if ($signupFooter.length > 0) {
            var signup = signupViewFactory.create();
            signup.init($signupFooter);
        }
    }
}
module.exports = footerView;
