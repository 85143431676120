var _productShowView = (function () {
    var _inst = {};

    var _bindUi = () => {

        var slickElement = $('.images');

        if (slickElement.find('a').length === 1) {
            return;
        }

        slickElement.slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 5000,
            pauseOnHover: false,
            centerMode: false,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ]
        });

        slickElement.attr('data-show', true);
    };

    _inst.init = () => {
        _bindUi();
    };

    return _inst;
})();

module.exports = _productShowView;