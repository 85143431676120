var _menuSearch = (() => {
    var _inst = {};

    var _localStorageKey = 'searchSuggestions',
        _searchEndpoint = '/search?q=';

    var _addLocalSuggestion = (query) => {
        var storageSuggestions = localStorage.getItem(_localStorageKey);

        if (!storageSuggestions) {
            localStorage.setItem(_localStorageKey, JSON.stringify([query]));

            return;
        }

        var parsedStorage = JSON.parse(storageSuggestions);

        for (var key in parsedStorage) {
            if (!parsedStorage.hasOwnProperty(key)) {
                continue;
            }

            var value = parsedStorage[key];

            if (query.toLowerCase() === value.toLowerCase()) {
                return;
            }
        }

        parsedStorage.unshift(query);

        if (parsedStorage.length > 5) {
            parsedStorage.splice(-1, 1);
        }

        localStorage.setItem(_localStorageKey, JSON.stringify(parsedStorage));
    };

    var _renderLocalSuggestions = (parentElement) => {
        var searchSuggestions = JSON.parse(
            localStorage.getItem(_localStorageKey)
        );
        
        var element = $('<ul></ul>');

        for (var key in searchSuggestions) {
            if (!searchSuggestions.hasOwnProperty(key)) {
                continue;
            }
            var value = searchSuggestions[key],
                url = _searchEndpoint + value;

            var html =
                '<li data-type="local-suggest">' +
                    '<a href="' + url + '">' +
                        value +
                    '</a>' +
                '</li>';
            
            element.append(html);
        }

        $(parentElement).html(element);
    };

    var _onSubmit = (element) => {
      var query = element.val();

      _addLocalSuggestion(query);

        window.location.href = _searchEndpoint + query;
    };

    var _bindUi = () => {
        var inputTarget = '#search-menu-desctop',
            suggestTarget = '#search-suggest',
            suggestTargetParent = null;

        if ($(window).width() <= 906) {
            inputTarget = '#search-mobile';
            suggestTarget = '#search-suggest-mobile';
            suggestTargetParent = '.search-suggest-mobile';
        }

        var showTarget = suggestTargetParent ? suggestTargetParent : suggestTarget;

        $(inputTarget).onTypeSearch('/api/search', {
            onSuccess: function (response) {
                if (response.data.html.length > 21) {
                    $(suggestTarget).html(response.data.html);
                    $(showTarget).addClass('display');
                }
            },
            onUnderMinLength: function () {
                $(showTarget).removeClass('display');
            }
        });
        
        $(inputTarget).on('focus', function () {
           if ($(this).val().length < 3) {
               _renderLocalSuggestions(suggestTarget);
           }

            $(showTarget).addClass('display');
        });

        $(inputTarget).on('keypress', function (event) {
            if (event.which !== 13) {
                return;
            }
            
            _onSubmit($(this));
        });

        $(inputTarget).on('focusout', function (event) {
            setTimeout(function () {
                $(showTarget).removeClass('display');
            }, 100);
        });
    };

    _inst.init = () => {
        _bindUi();
    };

    return _inst;
})();

module.exports = _menuSearch;