
var _popAlert = (function() {
    "use strict";

    var translationController = require('../controllers/translationController.js')

    var _inst = {},
        $alertEl;

    var _popAlert = function(color, text, hideAfterSeconds) {
        $alertEl.css('background-color', color);
        $alertEl.find('.popalert__content').html(text);
        $alertEl.attr('data-state', 'visible');

        var timeOutVar;
        var hide = () => {
            clearTimeout(timeOutVar);
            // $alertEl.fadeOut(300, function(){ $(this).remove(); });
            $alertEl.attr('data-state', 'hidden');
        }

        timeOutVar = setTimeout(hide, hideAfterSeconds * 1000);
        $alertEl.on('click', '.popalert__close', hide);
    };

    _inst.init = function() {
        $alertEl = $('.popalert');

        var queryDict = {}
        location.search.substr(1).split("&").forEach(function(item) {queryDict[item.split("=")[0]] = item.split("=")[1]});

        // pop_alert=verified,
        // pop_alert=verify_error
        // pop_alert=loggedin&email=patrik.albertsson@mytaste.com (edited)
        // pop_alert=password_reset_sent?email=patrik.albertsson@mytaste.com
        // pop_alert=reset_password_token_invalid
        // pop_alert=reset_password_success

        if (queryDict.hasOwnProperty('pop_alert')) {
            switch(queryDict.pop_alert) {
                case "verified":
                    setTimeout(function() {
                        _popAlert("#4ECD65", translationController.get("Your account is now verified!"), 12);
                    }, 1000);
                    break;
                case "loggedin":
                    var email = queryDict.email;
                    var text = translationController.get('You are logged in with email :email');
                    text = text.replace(':email', email);
                    setTimeout(function() {
                        _popAlert("#4ECD65", text, 12);
                    }, 1000);
                    break;
                case "password_reset_sent":
                    var email = queryDict.email;
                    var text = translationController.get('An email has been sent to :email');
                    text = text.replace(':email', email);
                    setTimeout(function() {
                        _popAlert("#4ECD65", text, 12);
                    }, 1000);
                    break;
                case "password_reset_success":
                    var text = translationController.get('Your password has now been changed') + '!';
                    setTimeout(function() {
                        _popAlert("#4ECD65", text, 12);
                    }, 1000);
                    break;
                case "password_reset_token_invalid":
                    var text = translationController.get('ResetToken:NotFound');
                    setTimeout(function() {
                        _popAlert("#cc0000", text, 12);
                    }, 1000);
            }
        }
    };

    return _inst;
})();

module.exports = _popAlert;