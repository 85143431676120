
var brandListView = {
	
	init : function(){
		this.bindUi();
		
	},

	bindUi: function(){
		if($("#brandlist .product-item").length >= 1){
			$("#browse_products").slick({
				  dots: false,
				  infinite: true,
				  speed: 500,
				  slidesToShow: 5,
				  slidesToScroll: 2,
				  autoplay: false,
				  autoplaySpeed: 5000,
				  pauseOnHover: false,
				  centerMode: false,
				  variableWidth: true

			});
		}
	}
}
module.exports = brandListView;