var followDialogTpl = require('../../templates/followProductDialog.hbs');
// var joinView = require('../login/joinView');
var Cookies = require("js-cookie");
// var deviceService = require('../../services/deviceService');

var followProductView = {
    init: function () {
        this.bindUi();
    },

    bindUi: function () {
        var self = this;

        /*$('.followproduct').unbind().bind("click", function (e) {
            e.stopPropagation();
            e.preventDefault();

            var uid = Cookies.get('user_id');
            var elFollow = $(this);

            if (!uid || uid == '') {
                var dataFollow = {
                    follow: 'product',
                    id: elFollow.attr("id"),
                    price: elFollow.attr("data-price"),
                    img: elFollow.attr("data-img")
                }

                window.location.href = "/register";

                // if (deviceService.isHandheld) {
                //     window.location.href = "/register";
                // } else {
                //     // joinView.init("lightbox-join", dataFollow);
                // }

            } else {

                var altText = elFollow.attr("alt-text");
                if (altText) {
                    elFollow.attr("alt-text", elFollow.text());
                    elFollow.html(altText);

                }
                var res = self.update(elFollow.attr("id"), elFollow.attr("data-price"), elFollow.attr("data-img"));

            }
        });*/
    },

    update: function (id, price, img) {
        /*$.get('/api/follow/product/', {"id": id, "price": price, "img": img})
            .done(function (response) {
                console.log(response);
                if (response.status == "addded") {
                    $("#dialoger").html(followDialogTpl(response)).addClass("animated bounceInDown");
                    window.setTimeout(function () {
                        $("#dialoger").removeClass("animated bounceInDown").html("");
                    }, 4500);
                    $(".followproduct#" + id).addClass("active");
                    var count = $(".favorites-count").attr("data-count");
                    count++;
                    $(".favorites-count").html(count).attr("data-count", count).removeClass("hide");

                }
                else {
                    $("#dialoger").removeClass("animated bounceInDown").html("");
                    $(".followproduct#" + id).removeClass("acitve");

                }
            });*/
    }
}

module.exports = followProductView;