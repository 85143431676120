'use strict';

// Import laravel bootstrap-file 
require('./bootstrap');

// Import Handlebars libs
// var inArray = require('in-array');

// UA Parser
var parser = require('ua-parser-js');
window.uaObject = parser();

// ClientData
var clientData = require('./config/clientData');
clientData.init(window.clientData);

// Import Handebars
var Handlebars = require('hbsfy/runtime');
// Handlebars.registerHelper('inArray', inArray);

// Core helper classes for factories and pubsub.
window.outlet = {};
window.outlet.core = {
    factorify: require('./core/factorify'),
    publisherify: require('./core/publisherify'),
    subscriberify: require('./core/subscriberify')
};

// On type search
require('./components/v2/search/utils/onTypeSearch');

// TranslationController
window.translation = require('./controllers/translationController');
window.translation.init(
    clientData.get('translations') || {}
);

// Handlebars
window.hdbs = Handlebars;
Handlebars.registerHelper('t', function (key, options) {
    var t = window.translation.get(key);
    if (arguments.length > 1) {
        for (var i = 1, len = arguments.length; i < len; i++) {
            if (typeof arguments[i] !== "object") {
                t = t.replace(':x', arguments[i]);
            }
        }
    }
    return new Handlebars.SafeString(t);
});

// authController
var authController = require('./controllers/authController');
authController.init(window.outletSverigeUser || null);

// Import Views used by router
var facebookService = require('./services/facebookService.js');
var apiService = require('./services/apiService.js');

var browseController = require('./controllers/browseController');
var productBrowseController = require('./controllers/productBrowseController');
var modalController = require('./controllers/modalController');
var globalClickController = require('./controllers/globalClickController.js');
var dialogController = require('./controllers/dialogController.js');

var menuView = require('./components/menu/menuView');
var footerView = require('./components/footer/footerView');

var brandsView = require('./components/brands/brandsView');
var outletView = require('./components/outlet/outletView');
var brandListView = require('./components/outlet/brandListView');
var vouchView = require('./components/vouchers/vouchView');
var browseView = require('./components/browse/browseView');
var browseBrandFilterView = require("./components/browse/browseBrandFilterView");
var productView = require('./components/product/productView');
var profileView = require('./components/profile/profileView');
var followView = require('./components/follow/followView');
var resendPwView = require('./components/login/resendPasswordView');
var newShopView = require('./components/admin/newShopView');
var editCampaignView = require('./components/admin/editCampaignView');
var joinView = require('./components/login/joinView');
var loginView = require('./components/login/loginView').create();
var homeView = require('./components/home/homeView');
var resetPasswordView = require('./components/password/resetPasswordView');

var onboardView = require('./components/v2/views/user/onboardView');
var userBrandView = require('./components/v2/views/user/brandView');
var productShowView = require('./components/v2/views/product/productShowView');

facebookService.init();
apiService.init();

globalClickController.init();
dialogController.init();

// LANG
window.maincategories = {herr: "Herrkläder", dam: "Damkläder", barn: "Barnkläder", hem: "Möbler"};

// Create global router -
window.Router = require('./helpers/router');

// Setup Router --
Router.config({mode: 'history'});

window.prevPage = [];

// Init Layout components
menuView.init();
footerView.init();

if (clientData.get('locale') === 'sv_SE') {
    let SmartBanner = require('smart-app-banner');
    window.SB = new SmartBanner({
        appStoreLanguage: 'sv', // language code for the App Store (defaults to user's browser language)
        title: 'Outletsverige, först till rean.',
        author: 'Outletsverige',
        button: 'Visa',
        store: {
            ios: 'Hämta i App Store',
            android: 'Hämta i Google Play',
            windows: 'Hämta i Windows store'
        },
        price: {
            ios: 'FREE',
            android: 'FREE',
            windows: 'FREE'
        }
        // , theme: '' // put platform type ('ios', 'android', etc.) here to force single theme on all device
        // , icon: '' // full path to icon image if not using website icon image
        // , force: 'ios' // Uncomment for platform emulation
    });
}

// Footer Signup
// joinView.init("footersignup");

window.f = {
    c: {
        browseController: browseController,
        modalController: modalController,
        dialogController: dialogController,
        productBrowseController: productBrowseController,
    },
    v: {
        brandsView: brandsView,
        outletView: outletView,
        brandListView: brandListView,
        onboardView: onboardView,
        vouchView: vouchView,
        browseView: browseView,
        productView: productView,
        menuView: menuView,
        profileView: profileView,
        resendPwView: resendPwView,
        newShopView: newShopView,
        editCampaignView: editCampaignView,
        joinView: joinView,
        loginView: loginView,
        homeView: homeView,
        resetPasswordView: resetPasswordView
    },
    s: {
        deviceService: require('./services/deviceService'),
        facebookService: require('./services/facebookService'),
        apiService: require('./services/apiService')
    },
    i: {
        clientData: window.clientData
    },
    debug: {
    }
};

// Define all routes
Router
    .add(/produkt\/(.*)/, function () {
        productShowView.init();
    })
    .add(/minoutlet\/varumarken/, function () {
        userBrandView.init();

    })
    .add(/minoutlet/, function () {
        profileView.init();

    })
    .add(/kartan/, function () {
        outletView.init();

    })
    .add(/outlet\/(.*)/, function () {
        outletView.init();

    })
    .add(/a\/(.*)/, function () {
        outletView.init();

    })
    .add(/b\/(.*)/, function () {
        brandListView.init();

    })
    .add(/varumarken/, function () {
        brandsView.init();

    })
    .add(/kampanjer/, function () {
        vouchView.init();

    })
    .add(/blackfriday/, function () {
        vouchView.init();

    })
    .add(/kampanjer\/(.*)/, function () {
        vouchView.init();

    })
    .add(/setup/, function () {
        onboardView.init();

    })
    .add(/varumarken\/(.*)/, function () {
        brandsView.init();

    })
    .add(/online/, function () {
        if (productView.checkBackButton()) {
            homeView.init();
        }
    })
    .add(/online/, function () {
        if (productView.checkBackButton()) {
            homeView.init();
        }
    })
    .add(/online\/(.*)/, function () {
        console.log("online wildcard");
        if (productView.checkBackButton()) {
            homeView.init();
        }
    })
    .add(/rea/, function () {
        console.log("rea wildcard");
        if (productView.checkBackButton()) {
            homeView.init();
        }
    })
    .add(/login/, function () {
        var el = $('.signup-fullpage');
        loginView.init(el);
    })
    .add(/register/, function () {
        joinView.init(null);
    })
    .add(/password\/reset\/[A-z 0-9]{32}/, function () {
        resetPasswordView.init();
    })
    .add(/password\/reset/, function () {
        resendPwView.init();
    })
    .add(/password\/forgot/, function () {

    })
    .add(/search/, function () {
        if (!productBrowseController.isInited()) {
            productBrowseController.init(true);
        }
    })
    .add(/admin\/(.*)\/suggest/, function () {
        newShopView.init();
    })
    .add(/admin\/email\/(.*)/, function () {
        console.log("admin new " + arguments[1]);
    })
    .add(/admin\/campaign\/new/, function () {
        editCampaignView.init();
    })
    .add(/admin\/campaign\/edit/, function () {
        editCampaignView.init();
    })
    .add(/favoriter/, function () {
        console.log("faves");
        if (productView.checkBackButton()) {
            browseView.init();
            browseView.bindProductsUi();
        }
    })
    .add(/om/, function () {
        productView.checkBackButton();
    })
    .add(/info/, function () {
        productView.checkBackButton();
    })
    .add(/anvandarvillkor/, function() {
        productView.checkBackButton();
    })
    .add(/anvandare/, function () {
        productView.checkBackButton();
    })
    .add(/forbutik/, function () {
        productView.checkBackButton();
    })
    .add(/online\/klader/, function () {
        console.log("Kläder ");
        if (productView.checkBackButton()) {

        }
    })
    .add(/stylesheet/, function () {
        console.log("stylesheet");
    })
    .add(/(.*)\/v\/(.*)\/(.*)/, function () {
        if (!productBrowseController.isInited()) {
            productBrowseController.init();
        }
    })
    .add(/(.*)\/v\/(.*)/, function () {
        if (!productBrowseController.isInited()) {
            productBrowseController.init(true);
        } else {
            productBrowseController.setTopCategoryInit(true);
        }
    })

    .add(/(.*)\/(.*)\/(.*)/, function () {
        if (!productBrowseController.isInited()) {
            productBrowseController.init();
        }
    })
    .add(/(.*)\/(.*)/, function () {
        if (!productBrowseController.isInited()) {
            productBrowseController.init();
        }

        // if (arguments[0] == 1) {
        //     browseController.init(arguments[1], arguments[2]);
        // } else {
        //     browseController.ajaxInit();
        // }
    })
    .add(/(\S.*)/, function () {
        //  TYPE
        if (arguments[0] === 1) {
            if (!productBrowseController.isInited()) {
                productBrowseController.init(true);
            } else {
                productBrowseController.setTopCategoryInit(true);
            }
        }
    })
    .add(function () {
        if (productView.checkBackButton()) {
            homeView.init();
        }
    })
    .check('', 1).listen(); // CHECK 1 FOR FULL RELOAD
