
window._ = require('lodash');

window.$ = window.jQuery = require('jquery');
window.Laravel = { csrfToken: $('meta[name=csrf-token]').attr("content") };
require('./helpers/jquery-ui.min');

require('bootstrap-sass');
//require('bootstrap-sass');

window.axios = require('axios');

require('bootstrap-sass');

var deviceService = require('./services/deviceService');
deviceService.init();

var apiService = require('./services/apiService');
window.apiService = apiService;

window.axios.defaults.headers.common = {
    'X-CSRF-TOKEN': window.Laravel.csrfToken,
    'X-Requested-With': 'XMLHttpRequest'
};

Array.prototype.remove = function(from, to) {
  var rest = this.slice((to || from) + 1 || this.length);
  this.length = from < 0 ? this.length + from : from;
  return this.push.apply(this, rest);
};