// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h2 class=\"dialog__item__header\">Produkt sparad</h2>\n<div class=\"dialog__item__content\">\n    <img class=\"content__image\"src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"img") || (depth0 != null ? lookupProperty(depth0,"img") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"img","hash":{},"data":data,"loc":{"start":{"line":3,"column":36},"end":{"line":3,"column":43}}}) : helper)))
    + "\"/>\n    <p class=\"content__text\">Du blir meddelad när priset sänks under <b>"
    + alias4(((helper = (helper = lookupProperty(helpers,"price") || (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"price","hash":{},"data":data,"loc":{"start":{"line":4,"column":72},"end":{"line":4,"column":81}}}) : helper)))
    + " kr!</b></p>\n    <a class=\"content__cta\" href=\"/minoutlet/\">\n        <div class=\"btn btn-default\">Visa favoriter &rarr;</div>\n    </a>\n</div>\n<div class=\"dialog__closebtn\"><svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 19.1 19.1\" xml:space=\"preserve\"><path d=\"M0.9,0.6c0.8-0.8,2-0.8,2.8,0l14.8,14.8c0.8,0.8,0.8,2,0,2.8s-2,0.8-2.8,0L0.9,3.4C0.1,2.6,0.1,1.4,0.9,0.6z\"></path><path d=\"M0.6,18.5c-0.8-0.8-0.8-2.1,0-2.9l15-15c0.8-0.8,2.1-0.8,2.9,0s0.8,2.1,0,2.9l-15,15C2.7,19.3,1.4,19.3,0.6,18.5z\"></path></svg></div>\n\n<!--\nOLD\n<div class=\"dialog_message\">\n	<div class=\"alert alert-success\">\n		<h2>Produkt sparad <span class=\"close\"></span></h2>\n		<div class=\"inner\">\n			<p class=\"\">Du blir meddelad när priset sänks under <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"price") || (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"price","hash":{},"data":data,"loc":{"start":{"line":17,"column":61},"end":{"line":17,"column":70}}}) : helper)))
    + " kr!</span></p>\n			<img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"img") || (depth0 != null ? lookupProperty(depth0,"img") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"img","hash":{},"data":data,"loc":{"start":{"line":18,"column":13},"end":{"line":18,"column":20}}}) : helper)))
    + "\"/>\n			<a href=\"/minoutlet/\">\n				<div class=\"btn btn-default\">Visa favoriter &rarr;</div>\n			</a>\n		</div>\n	</div>\n</div>-->\n";
},"useData":true});
