var _modalController = (function() {

    var _loginViewFactory = require('../components/login/loginView');
    var _signupViewFactory = require('../components/login/signupView');

    var _inst = {};

    var _activeModal = null;

    var _modals = {
        login: {
            isActive: false,
            isRendered: false,
            tmpl: require('../templates/login.hbs'),
            instance: _loginViewFactory.create(),
            $rendered: null,
            closeOnClickOutside: true
        },
        signup: {
            isActive: false,
            isRendered: false,
            tmpl: require('../templates/signup.hbs'),
            instance: _signupViewFactory.create(),
            $rendered: null,
            closeOnClickOutside: true
        },
        policy: {
            isActive: false,
            isRendered: false,
            tmpl: require('../templates/policy.hbs'),
            $rendered: null,
            closeOnClickOutside: false
        }
    };


    // Listen for click outside on before layer
    var _clickOutsideStateActive = (active) => {
        var _handleBodyClick = (e) => {
            // We clicked something inside a modal
            if ($(e.target).closest('.modal-container').length === 0) {
                _inst.closeActiveModal();
            }
        };
        if (active) {
            $('body').on('click', _handleBodyClick);
        } else {
            $('body').off('click', _handleBodyClick);
        }
    };

    var _openModal = (modalObj) => {
        if (_activeModal) return;

        _activeModal = modalObj;
        $('body').addClass('modal-active prevent-overflow');
        modalObj.$rendered.attr('data-state', 'prepare');

        setTimeout(function() {
            modalObj.$rendered.attr('data-state', 'open');
            $('body').addClass('modal-open');

            if (modalObj.closeOnClickOutside) {
                _clickOutsideStateActive(true);
            }
        }, 100);
    };

    var _closeModal = (callback) => {
        if (!_activeModal) return;

        if (_activeModal.closeOnClickOutside) _clickOutsideStateActive(false);

        $('body').removeClass('modal-open prevent-overflow');
        _activeModal.$rendered.attr('data-state', 'prepare');
        setTimeout(function() {
            _activeModal.$rendered.attr('data-state', 'closed');
            $('body').removeClass('modal-active');
            _activeModal.$rendered.remove();
            
            setTimeout(function() {
                _activeModal = null;
            }, 1);
        }, 100);

        if (callback) setTimeout(callback, 500);
    };

    var _renderModalInDOM = (key, obj) => {
        obj = obj || {};

        if (!_modals[key]) throw new Error("No template with that key");

        var modal = _modals[key];

        // Create jq element from template
        modal.$rendered = $(modal.tmpl(obj));

        // Add to DOM
        $('body').append(modal.$rendered);
        modal.isActive = true;

        // Init the factory instance
        if (modal.hasOwnProperty('instance')) {
            modal.instance.init(modal.$rendered);
        }

        return modal;
    };

    // Open login modal
    _inst.openLogin = () => {
        if (_activeModal) {
            console.warn("please close opened modal before opening another.");
            return;
        }

        var modal = _renderModalInDOM('login');

        modal.$rendered.on('click', '.closebtn', function(e) {
            _inst.closeActiveModal();
        });

        _openModal(modal);
    };

    // Open signup modal
    _inst.openSignup = (obj) => {
        if (_activeModal) {
            console.warn("please close opened modal before opening another.");
            return;
        }

        var modal = _renderModalInDOM('signup', obj);
        modal.$rendered.on('click', '.closebtn', function(e) {
            _inst.closeActiveModal();
        });

        modal.instance.injectObject(obj || {});
        _openModal(modal);
    };

    _inst.openPolicy = () => {
        if (_activeModal) {
            console.warn("please close opened modal before opening another.");
            return;
        }

        var modal = _renderModalInDOM('policy');

        modal.$rendered.on('click', '.policy__submit-btn', function (e) {
            _inst.closeActiveModal();
        });

        _openModal(modal);
    }

    Object.defineProperty(_inst, "hasActiveModal", {get: function() {
        return _activeModal ? true : false;
    }});

    _inst.closeActiveModal = (callback) => {
        _closeModal(callback || null);
    };

    return _inst;
})();

module.exports = _modalController;