var listProductsTpl = require('../../templates/listProducts.hbs');

var previousProducts = {

    init: function () {

    },

    save: function (id) {
        var prevs = -1;
        var prevStored = localStorage.getItem("previousproducts");
        if (prevStored) {
            prevs = JSON.parse(prevStored);
            if (prevs.indexOf(id) === -1) {
                if (prevs.length >= 8) {
                    prevs.splice(-1, 1);
                }
                prevs.unshift(id);

            }
            else {
                prevs = -1;
            }
        }
        else {
            prevs = [id];
        }

        // SET NEW ARRAY
        if (prevs !== -1) {
            localStorage.setItem("previousproducts", JSON.stringify(prevs));
            this.render();
        }
        console.log(prevs);

    },

    render: function () {

		try {
            var prevStored = localStorage.getItem("previousproducts");
            if (prevStored) {
                prevs = JSON.parse(prevStored);
                $.get('/api/previousproducts/', {data: prevs})
                    .done(function (response) {
                            // CHECK IF LAST REQUEST = LAST REPSONSE
                            $(".previousproducts .browse-products").html(listProductsTpl(response));

                            if ($(window).width() < 1000) {
                                $(".previousproducts .browse-products").slick({
                                    dots: false,

                                    speed: 500,
                                    autoplay: false,
                                    autoplaySpeed: 5000,
                                    pauseOnHover: false,
                                    centerMode: false,
                                    variableWidth: true

                                });
                            }
                        }
                    );

                this.bindUi();
            }
        } catch(e) {
		    console.warn(e);
        }

    },

    bindUi: function () {
        $("#clearprevious").unbind().bind("click", function () {
            localStorage.setItem("previousproducts", "");
            $(".previousproducts .browse-products").html("");

        });
    }


}
module.exports = previousProducts;