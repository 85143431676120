var authController =  (function () {
    var _inst = {};
    var _user;

    _inst.init = (user) => {
        _user = user;
    };

    _inst.isLoggedIn = () => {
        return _user ? true : false;
    };

    return _inst;
})();

module.exports = authController;