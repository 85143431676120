var _productFollow = (() => {
    var _inst = {};

    // Dependencies
    var _apiService = require('../../../services/apiService'),
        _dialogController = require('../../../controllers/dialogController');

    var _setFollowingState = (target, state) => {
        var followState = state ? 'true' : 'false',
            event = state ? 'unfollowproduct' : 'followproduct';

        target.attr('data-following', followState);
        target.attr('data-event', event);
    };

    _inst.followProduct = (id, target, callback) => {
        target = target || null;

        if (target) {
            _setFollowingState(target, true);
        }

        _apiService.followProduct(id).then((response) => {
            // Show dialog
            var identifier = 'product_' + id;

            _dialogController.appendDialog(
                response.data.html,
                identifier
            );

            if (callback) {
                callback();
            }
        });
    };

    _inst.unfollowProduct = (id, target, callback) => {
        target = target || null;

        if (target) {
            _setFollowingState(target, false);
        }

        _apiService.unfollowProduct(id).then((resp) => {
            if (callback) {
                callback();
            }
        });
    };

    return _inst;
})();

module.exports = _productFollow;