var _brandFollow = (() => {
    var _inst = {};

    // Dependencies
    var _apiService = require('../../../services/apiService'),
        _dialogController = require('../../../controllers/dialogController');

    _inst.followBrand = (id, genderId, callback) => {
        _apiService.followBrand(id, genderId).then((response) => {
            // Show dialog
            var identifier = 'brand_' + id;

            _dialogController.appendDialog(
                response.data.html,
                identifier
            );

            if (callback) {
                callback();
            }
        });

        _inst.updateFollowerCount(id, genderId, true);
    };

    _inst.unfollowBrand = (id, genderId, callback) => {
        _apiService.unfollowBrand(id, genderId).then((resp) => {
            if (callback) {
                callback();
            }
        });

        _inst.updateFollowerCount(id, genderId, false);
    };

    _inst.updateFollowerCount = (brandId, genderId, followingState) => {
        var countTargetSelector = '[data-follower-count-id=brand][data-brand-id="'
            + brandId + '"][data-gender-id="'
            + genderId + '"]',

            countTargets = $(countTargetSelector);
            if (countTargets.length === 0) {
                return;
            }

        var countElement = countTargets.first(),
            currentCount = parseInt(countElement.text());

        countTargets.text(followingState === true ? currentCount + 1 : currentCount - 1);
    };

    return _inst;
})();

module.exports = _brandFollow;