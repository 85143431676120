// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"login__modal\" data-state=\"closed\">\n    <section class=\"container signup-modal modal-container\">\n\n        <div class=\"signup-splash\">\n            <div class=\"signup-pros\">\n                <div class=\"inner\">\n                    <h3>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Login",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":24},"end":{"line":7,"column":38}}}))
    + "</h3>\n                </div>\n\n            </div>\n            <div class=\"login-form\">\n\n                <div class=\"inner\">\n                    <form class=\"form-horizontal\" role=\"form\" method=\"POST\" action=\"/login\">\n\n                        <input type=\"email\" class=\"email input-large\" placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"E-mail",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":83},"end":{"line":16,"column":98}}}))
    + "\" name=\"email\" value=\"\" required=\"\" autofocus=\"\">\n                        <label class=\"input-error-msg\" for=\"email\"></label>\n\n                        <input type=\"password\" class=\"password input-large\" name=\"password\" placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":105},"end":{"line":19,"column":122}}}))
    + "\">\n                        <label class=\"input-error-msg\" for=\"password\"></label>\n\n                        <button type=\"submit\" class=\"btn btn-square btn-success submit\" name=\"login\" data-loading=\"false\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Login",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":122},"end":{"line":22,"column":135}}}))
    + "</button>\n                        <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Have you forgotten your password?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":27},"end":{"line":23,"column":68}}}))
    + " <a href=\"/password/reset/\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Reset password here.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":96},"end":{"line":23,"column":124}}}))
    + "</a></p>\n\n                    </form>\n                </div>\n\n                <div class=\"form__or\">\n                    <h4><span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"or",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":30},"end":{"line":29,"column":40}}}))
    + "</span></h4>\n                </div>\n\n                <div class=\"fb-join inner\">\n                    <button type=\"submit\" class=\"btn btn-square btn-success btn-fb\" data-loading=\"false\" name=\"fbjoin\" data-loading=\"false\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Login with facebook",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":140},"end":{"line":33,"column":167}}}))
    + "</button>\n                </div>\n\n                <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Do not have an account?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":19},"end":{"line":36,"column":50}}}))
    + " <a href=\"/register/\" data-event=\"signupmodal\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"Create account here.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":97},"end":{"line":36,"column":125}}}))
    + "</a></p>\n\n            </div>\n        </div>\n\n        <div class=\"closebtn\">\n            <svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 19.1 19.1\" xml:space=\"preserve\"><path d=\"M0.9,0.6c0.8-0.8,2-0.8,2.8,0l14.8,14.8c0.8,0.8,0.8,2,0,2.8s-2,0.8-2.8,0L0.9,3.4C0.1,2.6,0.1,1.4,0.9,0.6z\"></path><path d=\"M0.6,18.5c-0.8-0.8-0.8-2.1,0-2.9l15-15c0.8-0.8,2.1-0.8,2.9,0s0.8,2.1,0,2.9l-15,15C2.7,19.3,1.4,19.3,0.6,18.5z\"></path></svg>\n        </div>\n    </section>\n</div>";
},"useData":true});
