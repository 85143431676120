

var _joinView = (function() {
    var _inst = {};

    var translationController = require('../../controllers/translationController.js');
    var apiService = require('../../services/apiService.js');
    var facebookService = require('../../services/facebookService.js');
    var addKidsTpl = require('../../templates/addKids.hbs');

    var elements = {},
        $rootElement = null;

    // DEPRECATED!!!

    _inst.init = (el) => {

        console.warn("WARNING: joinView.js is deprecated, now use the factory function signupView.js instead");

        $rootElement = el || $('.signup-form');

        elements.$interestsCheckboxes = $rootElement.find('.checkers');
        elements.$registerKids = $rootElement.find('.register-kids');
        elements.$registerKidsAddBtn = $rootElement.find('.register-kids .add');
        elements.$registerKidsAddedKids = $rootElement.find('.register-kids .addedkids');

        elements.$emailField = $rootElement.find('input[type=email]');
        elements.$emailErrorField = $rootElement.find('.input-error-msg[for=email]');

        elements.$registerBtn = $rootElement.find('button[name=signup]');
        elements.$facebookBtn = $rootElement.find('button[name=fbjoin]');

        _inst.bindUi();
    }

    _inst.bindUi = () => {

        elements.$interestsCheckboxes.on('click', '.checkitem .customcheck', (e) => {

            $(e.currentTarget).toggleClass('checked');

            console.log(e.currentTarget);

            if ($(e.currentTarget).data('id') == "barn") {
                elements.$registerKids.toggleClass("hidden");
            }
        });

        elements.$registerKidsAddBtn.on('click', () => {
            elements.$registerKidsAddedKids.append(addKidsTpl());
        });

        elements.$registerKidsAddedKids.on('click', '.remove', (e) => {
            let $pElement = e.currentTarget.closest('.kid-contain');
            if ($pElement) {
                $pElement.remove();
            }
        });

        var getFormData = () => {
            // Checkboxes
            var isChecked = elements.$interestsCheckboxes
                .find('.customcheck')
                .map(function() {
                    return $(this).hasClass('checked') ? $(this).data('id') : null;
                })
                .get();

            var kids = [];
            if (isChecked.indexOf('barn') > -1) {
                kids = elements.$registerKids.find('input.newkid')
                    .map(function() {
                        return $(this).val() || null;
                    })
                    .get();
            }

            // Fields
            let email = elements.$emailField.val();

            return {
                kids: kids,
                email: email,
                interests: isChecked
            }
        }

        // Forms

        elements.$registerBtn.on('click', (e) => {
            e.preventDefault();
            e.stopImmediatePropagation();

            var formData = getFormData(),
                $this = $(e.currentTarget);

            $this.attr('data-loading', true);
            apiService.register(formData.email, formData.interests, formData.kids)
                .then(function(resp) {

                    if(resp.status) {
                        window.location = resp.data.profile;
                    }

                    console.log("resp:", resp);


                })
                .catch(function(err) {
                    $this.attr('data-loading', false);
                    var errResp = err.hasOwnProperty('responseJSON') ? err.responseJSON : null;

                    var message;
                    if (errResp && errResp.error && errResp.error.type === 'EmailExistsException') {
                        message = translationController.get('Error:Mail already registered')
                    }
                    else if (errResp && err.message) {
                        message = errResp.message;
                    } else {
                        // Generic error
                        message = translationController.get('Error:Generic');
                    }
                    elements.$emailErrorField.html(message);
                    elements.$emailField.addClass('error');
                    elements.$emailErrorField.addClass('active');
                });

        });

        // FACEBOOK

        if (elements.$facebookBtn) {
            elements.$facebookBtn.on('click', function(e) {
                e.preventDefault();
                e.stopImmediatePropagation();

                var formData = getFormData(),
                    $this = $(this);

                $this.attr('data-loading', true);
                if (facebookService.authToken) {

                    apiService.registerFacebook(facebookService.authToken, formData.interests, formData.kids)
                        .then((resp) => {

                          if(resp.status) {
                            window.location = resp.data.profile;
                          }

                            console.log(resp);
                        })
                        .catch((err) => {
                            $this.attr('data-loading', false);
                            console.log(err);
                        });

                } else {

                    facebookService.login()
                        .then(function(response) {
                            console.log(response);
                            apiService.registerFacebook(facebookService.authToken, formData.interests, formData.kids)
                                .then((resp) => {
                                    console.log(resp);
                                })
                                .catch((err) => {
                                    $this.attr('data-loading', false);
                                    console.log(err);
                                });
                        })
                        .catch(function(response) {
                            console.log(response);
                        });

                }

            });
        }

    }

    return _inst;
})();

module.exports = _joinView;