var followView = require('../follow/followView');
var profileView = {
	init : function(){

		this.bindUi();
	},

	bindUi : function(){
		var self = this;

		$('#profile_categories').on('submit',$.proxy( self.submitProfile, this) );
		followView.init();

	},

	submitProfile : function(e){

		e.preventDefault(e);
		$("[role=alert]").addClass("hide");

		$.ajax({
	        type:"POST",
	        url:'/api/user/me',
	        data: $(e.currentTarget).serialize(),
	        dataType: 'json',
	        success: function(data){
	            console.log(data);
	            $("[role=alert]").removeClass("hide");
	        },
	        error: function(data){

	        }
		});
		return false;		
	}
}
module.exports = profileView;