var translationController = (function() {
    var _inst = {};

    var _translations = null;

    _inst.init = function(translationObject) {
        _translations = translationObject;
    };

    _inst.get = function(key) {
        if (_translations.hasOwnProperty(key)) {
            return _translations[key];
        }
        console.warn("No translation for [" + key + "]");
        return key;
    };

    return _inst;
})();

module.exports = translationController;