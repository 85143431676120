var vouchView = {

    data: {},

    init: function () {
        this.bindUi();
    },

    bindUi: function () {
        $(".pressbutton").bind("click", function () {

            // Get the id
            var id = $(this).attr('id');

            // If user pressed an activte button, wanting to
            // deactivate it. And its not the alla button.
            if ($(this).hasClass('active') && id !== "alla") {

                // When deactivate a button that is not the alla button
                // we instead activate the alla button.
                $('.pressbutton.active').removeClass('active');
                $('.pressbutton#alla').addClass('active');
                id = "alla";

            } else { // Else we activate the button

                // Deactivate all buttons
                $('.pressbutton.active').removeClass('active');
                $(this).addClass('active');
            }


            switch(id) {
                case "alla":
                    $(".campaign-list-item").removeClass("hide");
                    break;
                default:
                    $(".campaign-list-item").addClass("hide");
                    $(".campaign-list-item." + id).removeClass("hide");
            }


            // if ($(this).hasClass("active")) {
            //
            //     $(".campaign-list-item").removeClass("hide");
            //     $(this).removeClass("active")
            //
            // } else {
            //
            //     $(".campaign-list-item").addClass("hide");
            //     $(".filteritem.active").removeClass("active");
            //     $(this).addClass("active");

                // var id = $(this).attr("id");
                // if (id != "alla") {
                //     $(".campaign-list-item." + id).removeClass("hide");
                // } else {
                //     $(".campaign-list-item").removeClass("hide");
                // }

            // }
        });
    }
}

module.exports = vouchView;