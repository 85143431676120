
var _factorify = function(instance) {
    "use strict";

    /**
     * Core functionality to create a factory object
     *
     * @public
     * @module core/factorify
     * @type {function}
     *
     * @see module:core/subscriberify
     * @see module:core/publisherify
     *
     * @example
     *
     *  var _someClass = function() {
     *      return {
     *          init: function() {
     *              // INITED
     *              console.log(this.opts.hello);
     *          }
     *      }
     *  };
     *
     *  var _someClassFactory = mt.core.factorify(_someClass);
     *
     *  var someClassInstance = _someClassFactory.create({
     *      hello: "WORLD"
     *  });
     *
     *  var someClassInstance2 = _someClassFactory.create({
     *      hello: "HELLO"
     *  });
     *
     *  someClassInstance.init();
     *  // outputs WORLD
     *
     *  someClassInstance2.init();
     *  // outputs HELLO
     */

    var _arguments = arguments;
    var _instance = instance;

    if (typeof _instance !== "function") {
        throw new Error("Target for factorify must be a function returning a object. If not it will be a static factory.");
    }

    var _fact = {
        create(opts) {

            var inst = (function() {

                var _inst = _instance();
                var _opts = opts || {};
                var _instanceId = _.uniqueId();

                try {
                    Object.defineProperties(_inst, {
                        type: {
                            get() {
                                return "factory";
                            },
                            enumerable: true,
                            configurable: true
                        },

                        opts: {
                            get() {
                                return _opts;
                            },
                            set(val) {
                                return _opts = val;
                            },
                            enumerable: true
                        },

                        instanceId: {
                            get() {
                                return _instanceId;
                            },
                            enumerable: true
                        }
                    });

                } catch(e) {
                    throw new Error('Target functions must return a object.');
                }

                return _inst;

            })();

            if (_arguments.length > 1) {
                var resp = inst;
                for(var i = 1; i < _arguments.length; i++) {
                    resp = _arguments[i](resp);
                }
                return resp;
            } else {
                return inst;
            }
        }
    };

    return _fact;
};

module.exports = _factorify;