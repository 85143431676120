
var _publisherify = function(instance, subFunctionality) {
    "use strict";

    /**
     * Core function to add publisher functionality to a class
     *
     * @public
     * @module core/publisherify
     * @type {function}
     *
     * @see module:core/factorify
     * @see module:core/subscriberify
     *
     */

    var _arguments = arguments;
    var _instance = instance;
    var _log = mt.ho.log('NOTIFY', 'color: gold; font-weight: bold; background: #555');

    /**
     * A notification object that is sent to subscribers when
     * running notify function
     *
     * @method notification
     * @access private
     *
     * @param pub {*} the current publisher class
     * @param key {string} the key
     * @param [value=null] {*} the value sent in the note
     * @param [args=null] {array} other arguments sent in note
     *
     * @returns {{publisher: *, key: *, value: (*|null), args: (*|null)}}
     */
    var notification = function(pub, key, value, args) {
        var _notification = {
            publisher: pub || _instance,
            key: key || _instance.type,
            value: value || null,
            args: args || null
        };

        // _log(_notification);

        return _notification;
    };

    return (function() {
        var _subscribers = [];

        var _getSubscriberIndex = function(inst) {
            for(var i = 0, len = _subscribers.length; i < len; i++) {
                if (_subscribers[i] === inst) return i;
            }
            return -1;
        };

        if (!_instance.hasOwnProperty('subscribe')) {
            /**
             * Adds a subscriber to the list of subscribers
             * all subscribers and notified when notify function
             * runs
             *
             * @method subscribe
             * @see module:core/subscriberify
             *
             * @param sub {module:core/subscriberify} a subscriberified object or object with a correctly implemented
             * notified function
             */
            _instance.subscribe = function(sub) {
                if (sub.hasOwnProperty('notified') && sub.notified.length > 0) {
                    if (_getSubscriberIndex(sub) === -1) {
                        _subscribers.push(sub);
                    }
                } else {
                    throw new Error("Only subscribers can subscribe to a publisher");
                }
            };
        }

        if (!_instance.hasOwnProperty('unsubscribe')) {
            /**
             * Unscubscribes from this publisher instance
             *
             * @method unsubscribe
             * @see module:core/subscriberify
             *
             * @param sub {module:core/subscriberify} a subscriberified object or object with a correctly implemented
             * notified function
             */
            _instance.unsubscribe = function(sub) {
                var s = _getSubscriberIndex(sub);
                if (s) {
                    _subscribers.splice(s, 1);
                }
            };
        }

        var oldType = "";
        if (_instance.hasOwnProperty('type')) {
            oldType = _instance.type + ":";
        }
        /**
         * The type of object this is
         *
         * @method type
         * @readonly
         *
         * @return {string} The type of object this is "publisher"
         */
        Object.defineProperty(_instance, "type", {
            get: function() {
                return oldType + "publisher";
            },
            configurable: true
        });

        /**
         * Get a list of subscribers
         *
         * @method subscribers
         * @readonly
         *
         * @return {array} Array of subscribers
         */
        Object.defineProperty(_instance, "subscribers", {get: function() {
            return _subscribers;
        }});


        if (!_instance.hasOwnProperty('notify')) {
            /**
             * Notifies all subscribers by sending a notification object
             * with specifiec value and key
             *
             * @method notify
             *
             * @param key {string} the note key
             * @param value {*} object sent in the notification
             * @param arguments {...*} any other arguments
             */
            _instance.notify = function(key, value) {
                var args;
                if (arguments.length > 2) {
                    args = arguments[2];
                }
                for(var i = 0, len = _subscribers.length; i < len; i++) {
                    _subscribers[i].notified(notification(_instance, key, value, args));
                }
            };
        }

        if (_arguments.length > 1) {
            var resp = _instance;
            for(var i = 1; i < _arguments.length; i++) {
                resp = _arguments[i](resp);
            }
            return resp;
        } else {
            return _instance;
        }

    })();

};

module.exports = _publisherify;