var _apiService = (function() {
    var _inst = {};

    _inst.init = () => {

        let csrf = document.querySelector('meta[name=csrf-token]');
        if (csrf) {
            csrf = csrf.content;
        }
        if (csrf && csrf.length > 0) {
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': csrf
                }
            });
        }

    };

    _inst.register = (email, interests, kids) => {

        return $.ajax({
            type: "POST",
            url: '/api/user/register',
            data: {
                email: email,
                male: interests.indexOf(2) > -1 ? 1 : interests.indexOf('herr') > -1 ? 1 : 0,
                female: interests.indexOf(1) > -1 ?  1 : interests.indexOf('dam') > -1 ? 1 : 0,
                kids: interests.indexOf(3) > -1 ? 1 : interests.indexOf('barn') > -1 ? 1 : 0,
                interior: interests.indexOf(4) > -1 ? 1 : interests.indexOf('hem') > -1 ? 1 : 0,
                children: kids,
                source: 'web'
            }
        });

    };

    _inst.registerFacebook = (access_token, interests, kids) => {

        return $.ajax({
            type: "POST",
            url: '/api/user/register/facebook',
            data: {
                access_token: access_token,
                male: interests.indexOf(2) > -1 ? 1 : interests.indexOf('herr') > -1 ? 1 : 0,
                female: interests.indexOf(1) > -1 ?  1 : interests.indexOf('dam') > -1 ? 1 : 0,
                kids: interests.indexOf(3) > -1 ? 1 : interests.indexOf('barn') > -1 ? 1 : 0,
                interior: interests.indexOf(4) > -1 ? 1 : interests.indexOf('hem') > -1 ? 1 : 0,
                children: kids,
                source: 'web'
            }
        });

    };

    _inst.login = (email, password) => {

        return $.ajax({
            type: "POST",
            url: '/api/user/login',
            data: {
                email: email,
                password: password
            },
            dataType: 'json'
        });
    };

    _inst.loginFacebook = (access_token) => {

        return $.ajax({
            type: "POST",
            url: "/api/user/login/facebook",
            data: {
                access_token: access_token
            }
        });

    };

    _inst.logout = () => {
        document.cookie = 'user_id=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = 'gender=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';

        return Promise.resolve();
    };

    _inst.resendPassword = (email) => {

        return $.ajax({
            type: "POST",
            url: '/api/user/password/reset',
            data: {
                email: email
            }
        });
    };

    _inst.resetPassword = (data) => {

        return $.ajax({
            type: "POST",
            url: '/api/user/password/reset/update',
            data: data
        });
    };

    _inst.productBrowse = (data) => {
        return $.ajax({
            type: "POST",
            url: '/api/browse',
            data: data
        });
    };

    _inst.getProduct = (id) => {
        return $.ajax({
            type: "GET",
            url: '/api/product/' + parseInt(id)
        });
    };

    _inst.followProduct = (id) => {
        return $.ajax({
            type: "POST",
            url: "/api/product/" + id + "/follow"
        });
    };

    _inst.unfollowProduct = (id) => {
        return $.ajax({
            type: "DELETE",
            url: "/api/product/" + id + "/follow"
        });
    };

    _inst.followBrand = (id, genderId) => {
        return $.ajax({
            type: "POST",
            url: "/api/brand/" + id + "/followers/" + genderId,
        });
    };

    _inst.unfollowBrand = (id, genderId) => {
        return $.ajax({
            type: "DELETE",
            url: "/api/brand/" + id + "/followers/" + genderId
        });
    };

    _inst.onKeySearch = (url, query) => {
        return $.ajax({
            type: 'GET',
            url: url + '/' + query
        });
    };

    return _inst;
})();

module.exports = _apiService;
