var _clientData = (function () {
    var _inst = {},
        _isInited = false,
        _clientData = {};

    var _ = window._;

    _inst.init = clientData => {
        if (typeof clientData !== 'object') {
            console.warn('ClientData has to be an object.');

            return false;
        }

        _isInited = true;

        _clientData = clientData;
    };

    _inst.isInited = () => {
        return _isInited;
    };

    _inst.get = key => {
        return _.get(_clientData, key);
    };

    return _inst;
})();

module.exports = _clientData;