var _onboardView = (function () {
    var _inst = {},
        _isInited = false;

    var _bindUi = () => {

        // Slick products
        $(".list-group").slick({
            dots: false,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 5000,
            pauseOnHover: false,
            centerMode: false,
            variableWidth: true
        });

        var resultTargetSelector = $('#brand__search');

        $('#brandsearch-full').onTypeSearch('/api/search/brands', {
            onSuccess: function (response) {
                if (response.data.html === '') {
                    resultTargetSelector.attr('data-show', false);

                    return;
                }

                resultTargetSelector
                    .find('.searchresults')
                    .html(response.data.html);

                if (resultTargetSelector.attr('data-show') === 'false') {
                    resultTargetSelector.attr('data-show', true);
                }
            },
            onError: function () {
                resultTargetSelector.attr('data-show', false);
            },
            onUnderMinLength: function () {
                resultTargetSelector.attr('data-show', false);
            }
        });
    };

    _inst.init = () => {
        console.log('INIT ONBOARD');

        _isInited = true;

        // Todo: Fetch brands for search

        _bindUi();
    };

    return _inst;
})();

module.exports = _onboardView;