var _resetPasswordView = (function () {
    var translationController = require('../../controllers/translationController');
    var apiService = require('../../services/apiService');

    var _inst = {};

    var elements = {},
        $rootElement = null;

    _inst.init = () => {
        $rootElement = $('.signup-fullpage');

        elements.$passwordField = $rootElement.find('#password');
        elements.$passwordErrorField = $rootElement.find('.input-error-msg[for=password]');

        elements.$passwordConfirmationField = $rootElement.find('#password_confirmation');
        elements.$passwordConfirmationErrorField = $rootElement.find('.input-error-msg[for=password_confirmation]');

        elements.$tokenField = $rootElement.find('#token');
        elements.$tokenErrorField = $rootElement.find('.input-error-msg[for=token]');

        elements.$submitBtn = $rootElement.find('input[type=submit]');

        _inst.bindUI();
    }

    _inst.bindUI = () => {
        elements.$submitBtn.on('click', function (event) {
            event.preventDefault();
            event.stopImmediatePropagation();

            _inst.removeErrorsFromFields();

            if (elements.$passwordField.val().length < 6) {
                elements.$passwordErrorField.html(translationController.get('Password must be at least 6 characters long.'));
                elements.$passwordField.addClass('error');
                elements.$passwordErrorField.addClass('active');

                return false;
            }

            if (elements.$passwordField.val() !== elements.$passwordConfirmationField.val()) {
                elements.$passwordConfirmationErrorField.html(translationController.get('Passwords does not match.'));
                elements.$passwordConfirmationField.addClass('error');
                elements.$passwordConfirmationErrorField.addClass('active');

                return false;
            }

            var data = {
                password: elements.$passwordField.val(),
                password_confirmation: elements.$passwordConfirmationField.val(),
                token: elements.$tokenField.val()
            };

            apiService.resetPassword(data)
                .then((resp) => {
                    _inst.removeErrorsFromFields();

                    if (resp.status) {
                        window.location.href = resp.data.redirect;
                    }
                })
                .catch((err) => {
                    if (err.responseJSON.status == 'error') {
                        elements.$tokenErrorField.html(err.responseJSON.message);
                        elements.$tokenField.addClass('error');
                        elements.$tokenErrorField.addClass('active');
                    }
                });

        })
    }

    _inst.removeErrorsFromFields = () => {
        elements.$tokenField.removeClass('error');
        elements.$tokenErrorField.removeClass('active');

        elements.$passwordField.removeClass('error');
        elements.$passwordErrorField.removeClass('active');

        elements.$passwordConfirmationField.removeClass('error');
        elements.$passwordConfirmationErrorField.removeClass('active');
    };

    return _inst;
})();

module.exports = _resetPasswordView;